import { addDays } from "utils/Date";

export { default } from "./Historical";

export const PERIOD_OPTIONS = [
    {
        id: 1,
        value: "Días",
        label: "Días",
        type: "days",
    },
    {
        id: 2,
        value: "Horas",
        label: "Horas",
        type: "hours",
    },
];

export const getVisitsPerHour = data => {
    const total = {};
    data.forEach(item => {

        // It's used to add the hour on the dot to the previous one
        const [hour, minutes] = item.date.split(":");
        const previousHour = minutes === "00" ? Number(hour) - 1 : hour;
        const correctHour = String(previousHour).length > 1 ? previousHour : `0${previousHour}`;
        total[correctHour] = (total[correctHour] ?? 0) + (item.value ?? 0);

        // Correct code
        // const [hour, minutes] = item.date.split(":");
        // total[hour] = (total[hour] ?? 0) + (item.value ?? 0);
    });
    const visits = Object.entries(total).map(([hour, value]) => ({
        date: `${hour}:00`,
        value,
    }));

    // return visits.sort((a, b) => Number(a.date.split(":")[0]) - Number(b.date.split(":")[0])).slice(0, visits.length - 1);
    return visits.sort((a, b) => Number(a.date.split(":")[0]) - Number(b.date.split(":")[0]));
};

export const getTextsFromPeaks = filterByDays => {
    const unit = filterByDays ? "Día" : "Hora";
    const termination = filterByDays ? "o" : "a";
    return {
        maximum: `${unit} más visitad${termination}:`,
        minimum: `${unit} menos visitad${termination}:`,
    };
};

export const DEFAULT_DATE = {
    start: addDays(new Date(), -7),
    end: addDays(new Date(), -1),
    day: addDays(new Date(), -7),
};
