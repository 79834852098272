//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
import { Row, Col, Card, Button, Dropdown, Nav, Table } from "react-bootstrap";
import Cookies from "universal-cookie";
import API from "../../api/Main";
import { Chart } from "chart.js";
import Footer from "../../components/Footer";
import DatePicker from "react-datepicker";
import Clock from "react-live-clock";
import { COLINA_EMAIL } from "constants/Customer";

//Import images
import iconHand from "../../imgs/manita.png";
import working from "../../imgs/Recurso10.png";

//Import styles
import "../../styles/Biosecurity/dashboard.css";
import "react-datepicker/dist/react-datepicker.css";

// Special graphs
import "chartjs-plugin-datalabels";
import "styles/marketing.scss";

class DashboardSuper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            graphic: [],
            lastEventsPartOne: [],
            lastEventsPartTwo: [],
            pagination: 1,
            camerasIn: [],
            camerasZones: [],
            groupsIn: [],
            groupsZones: [],
            lineGraphic: {},
            lineGraphic_2: {},
            barGraphic: {},
            barGraphic_2: {},
            pieGraphic: {},
            loadingStaticInLineIn: false,
            loadingStaticInLineZones: false,
            loadingStaticInBarIn: false,
            loadingStaticInBarZones: false,
            loadingStaticGender: false,
            loadingDynamicImgZones: false,
            loadingDynamicZones: false,
            loadingAforoMax: false,
            tempLogo: "",
            tempIdIn: "",
            tempIdZones: "",
            tempNameIn: "",
            tempNameZones: "",
            startDateStatic: new Date(),
            finishDateStatic: new Date(new Date().getTime() - 3600000),
            startDateDynamic: new Date(),
            finishDateDynamic: new Date(new Date().getTime() - 3600000),
            actualDate: new Date().toLocaleTimeString(),
            capacity: "",
            capacityGeneral: "",
            capacityTotal: "50000",
            porcentajeCapacity: "",
            valorTotalPersonasInTotal: 0,
            valorTotalPersonasTotalZones: 0,
            valorTotalPersonasOutTotal: 0,
        };
    }

    handleClickCameraIn = e => {
        this.setState({
            loadingDynamicImgZones: false,
            loadingDynamicZones: true,
            tempIdIn: e.target.id,
            tempNameIn: e.target.name,
        });
        this.updateDashboardIn(e.target.id, e.target.name, true, new Date().getTime(), new Date().getTime() - 1 * 3600 * 1000);
    };

    handleClickCameraZones = e => {
        this.setState({
            loadingDynamicImgZones: false,
            loadingDynamicZones: true,
            tempIdZones: e.target.id,
            tempNameZones: e.target.name,
        });
        this.updateDashboardZones(e.target.id, e.target.name, true, new Date().getTime(), new Date().getTime() - 1 * 3600 * 1000);
    };

    handleClickGroupIn = e => {
        localStorage.setItem("idGroupIn", e.target.id);
        localStorage.setItem("nameGroupIn", e.target.name);
        this.getListCamerasIn();
        this.updateDashboardIn(e.target.id, e.target.name, true, new Date().getTime(), new Date().getTime() - 1 * 3600 * 1000);

        this.setState({ capacity: "0" });
        this.setState({ capacityGeneral: "0" });
    };

    handleClickGroupZones = e => {
        localStorage.setItem("idGroupZones", e.target.id);
        localStorage.setItem("nameGroupZones", e.target.name);
        this.getListCamerasZones();
        this.updateDashboardZones(e.target.id, e.target.name, true, new Date().getTime(), new Date().getTime() - 1 * 3600 * 1000);
    };

    updateDashboardIn(id, name, isUpdate, startDate, finishDate) {
        this.hideObjectsZones();
        this.hideObjectsIn();
        this.showGraphicsIn(id, name, isUpdate, startDate, finishDate);
        //this.showUpdates();
    }

    updateDashboardZones(id, name, isUpdate, startDate, finishDate) {
        this.hideObjectsIn();
        this.hideObjectsZones();
        this.showGraphicsZones(id, name, isUpdate, startDate, finishDate);
        //this.showUpdates();
    }

    getListGroupsIn() {
        API.get("https://dot", "/resource_group_specific/?model=ML-D11&modelNo=ML-D7")
            .then(response => {
                var bBandera = true;
                if (response.ok) {
                    let global_item;
                    response.json().then(data => {
                        data.forEach(element => {
                            let item = (
                                <Dropdown.Item
                                    onClick={this.handleClickGroupIn}
                                    className="Camera"
                                    id={element.id}
                                    key={element.id}
                                    name={element.name}
                                >
                                    Grupo {element.name}
                                </Dropdown.Item>
                            );
                            if (bBandera) {
                                let item = (
                                    <Dropdown.Item
                                        onClick={this.handleClickGroupIn}
                                        className="Camera"
                                        id={"0d8c4933-111e-4544-b098-82f3f6001e3c"}
                                        key={"0d8c4933-111e-4544-b098-82f3f6001e3c"}
                                        name={"Todos los accesos"}
                                    >
                                        {"Todos los accesos"}
                                    </Dropdown.Item>
                                );

                                this.state.groupsIn.push(item);
                                bBandera = false;
                            }
                            this.state.groupsIn.push(item);
                        });

                        this.setState({
                            loading: false,
                        });
                        localStorage.setItem("idGroupIn", this.state.groupsIn[0].key);
                        localStorage.setItem("nameGroupIn", this.state.groupsIn[0].props.name);

                        this.getListCamerasIn();
                        this.updateDashboardIn(
                            localStorage.getItem("idGroupIn"),
                            localStorage.getItem("nameGroupIn"),
                            false,
                            new Date().getTime(),
                            new Date().getTime() - 1 * 3600 * 1000
                        );
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    getListGroupsZones() {
        API.get("https://dot", "/resource_group_specific/?model=ML-D7")
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        data.forEach(element => {
                            let item = (
                                <Dropdown.Item
                                    onClick={this.handleClickGroupZones}
                                    className="Camera"
                                    id={element.id}
                                    key={element.id}
                                    name={element.name}
                                >
                                    Grupo {element.name}
                                </Dropdown.Item>
                            );

                            this.state.groupsZones.push(item);
                        });

                        this.setState({
                            loading: false,
                        });

                        if (data.length != 0) {
                            localStorage.setItem("idGroupZones", this.state.groupsZones[0].key);
                            localStorage.setItem("nameGroupZones", this.state.groupsZones[0].props.name);
                            this.getListCamerasZones();
                            this.updateDashboardZones(
                                this.state.groupsZones[0].key,
                                this.state.groupsZones[0].props.name,
                                false,
                                new Date().getTime(),
                                new Date().getTime() - 1 * 3600 * 1000
                            );
                        }
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    getListCamerasIn() {
        this.setState({ camerasIn: [], tempIdIn: "", tempNameIn: "" });

        var id_group = "";
        if (localStorage.getItem("idGroupIn") == "0d8c4933-111e-4544-b098-82f3f6001e3c") {
            id_group = this.state["groupsIn"][1]["key"];
        } else {
            id_group = localStorage.getItem("idGroupIn");
        }

        API.get("https://dot", "/cameras/1" + "?groupId=" + id_group)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.length !== 0) {
                            data.forEach(element => {
                                let item = (
                                    <Dropdown.Item
                                        onClick={this.handleClickCameraIn}
                                        className="Camera"
                                        id={element.id}
                                        key={element.id}
                                        name={element.name}
                                    >
                                        Cámara {element.name}
                                    </Dropdown.Item>
                                );
                                this.state.camerasIn.push(item);
                            });

                            this.setState({
                                loading: false,
                                tempIdIn: this.state.camerasIn[0].key,
                                tempNameIn: this.state.camerasIn[0].props.name,

                                tempLogo:
                                    "https://storage.googleapis.com/logos-switchai/" + this.state.camerasIn[0].key + "/logo.png",
                            });

                            document.getElementById("buttonAforoGeneral").style.display = "";
                        } else {
                            document.getElementById("buttonAforoGeneral").style.display = "none";
                        }
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    getListCamerasZones() {
        this.setState({ camerasZones: [], tempIdZones: "", tempNameZones: "" });
        API.get("https://dot", "/cameras/1" + "?groupId=" + localStorage.getItem("idGroupZones"))
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.length !== 0) {
                            data.forEach(element => {
                                let item = (
                                    <Dropdown.Item
                                        onClick={this.handleClickCameraZones}
                                        className="Camera"
                                        id={element.id}
                                        key={element.id}
                                        name={element.name}
                                    >
                                        Cámara {element.name}
                                    </Dropdown.Item>
                                );
                                this.state.camerasZones.push(item);
                            });

                            this.setState({
                                loading: false,
                                tempIdZones: this.state.camerasZones[0].key,
                                tempNameZones: this.state.camerasZones[0].props.name,
                                tempLogo: "https://app.switchai.co/imgs/" + this.state.camerasZones[0].key + "/logo.png",
                            });

                            document.getElementById("buttonAforoGeneral").style.display = "";
                        } else {
                            document.getElementById("buttonAforoGeneral").style.display = "none";
                        }
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    getAforo(idGroupAforo) {
        this.setState({
            loadingAforo: true,
        });

        API.get("https://dot", "/analytics/capacity?groupId=" + localStorage.getItem(idGroupAforo))
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (this.state.groupsIn[1].key == "e90124bc-cd5e-4570-8313-6b6392dedc96") {
                            this.setState({ capacityTotal: 3500 });
                        }
                        this.setState({ capacity: data.response });
                    });
                    this.setState({
                        loadingAforo: false,
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    getAforoGeneral() {
        this.setState({
            loadingAforoGeneral: true,
        });

        let count = 0;
        let nCountAforo = 0;

        API.get("https://dot", "/analytics/capacity?groupId=" + this.state.groupsIn[1].key)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        const capacity_access = data.response.split(";");
                        count = capacity_access[0];
                        this.setState({ capacityGeneral: count }); // Check if this thing improve results.
                        try {
                            if (count !== 0) {
                                let porcentajeCount = ((count * 100) / this.state.capacityTotal).toFixed(2);
                                if (porcentajeCount < 101) {
                                    this.setState({
                                        loadingAforoMax: false,
                                        porcentajeCapacity: porcentajeCount,
                                    });
                                } else {
                                    this.setState({
                                        loadingAforoMax: true,
                                        porcentajeCapacity: "Aforo Máximo ",
                                    });
                                }
                            } else {
                                this.setState({
                                    loadingAforoMax: false,
                                    porcentajeCapacity: 0,
                                });
                            }
                        } catch (e) {}

                        this.setState({
                            loadingAforoGeneral: false,
                        });
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    showGraphicsIn(id, name, isUpdate, startDate, finishDate) {
        this.getAforo("idGroupIn");
        this.getAforoGeneral();
        let arrayXDateChartLine = [];
        let arrayYOutChartLine = [];
        let arrayYInChartLine = [];
        let arrayX2DateChartBar = [];
        let arrayY2InChartBar = [];
        let arrayY2OutChartBar = [];
        let dataTrafficIn = [];
        let dataTrafficOut = [];
        let configStateLine;
        let configStateBar;

        this.setState({
            loadingStaticInLineIn: true,
            loadingStaticInBarIn: true,
            loadingStaticGender: true,
        });

        if (localStorage.getItem("idGroupIn") == "0d8c4933-111e-4544-b098-82f3f6001e3c") {
            var nCountInterno = 0;
            var camerasTotal = {};
            var dict_out = {};
            var dict_in = {};
            this.state["groupsIn"].forEach(element => {
                API.get(
                    "https://dot",
                    "/analytics/ingress" + "?start=" + finishDate + "&end=" + startDate + "&groupId=" + element["key"]
                )
                    .then(response => {
                        if (response.ok) {
                            response.json().then(data => {
                                if (data.length !== 0) {
                                    document.getElementById("chartLineIn").style.display = "";
                                    document.getElementById("loadingLineIn").style.display = "none";
                                    document.getElementById("chartBarIn").style.display = "";
                                    document.getElementById("loadingBarChartIn").style.display = "none";

                                    data.forEach(element => {
                                        if (element.event_date in camerasTotal) {
                                            dict_out[element.event_date] = element.come_out + camerasTotal[element.event_date][0];
                                            dict_in[element.event_date] = element.come_in + camerasTotal[element.event_date][1];
                                            camerasTotal[element.event_date][0] = dict_out[element.event_date];
                                            camerasTotal[element.event_date][1] = dict_in[element.event_date];
                                        } else {
                                            dict_out[element.event_date] = element.come_out;
                                            dict_in[element.event_date] = element.come_in;
                                            var dict_in_out = { 0: element.come_out, 1: element.come_in };
                                            camerasTotal[element.event_date] = dict_in_out;
                                        }
                                    });

                                    nCountInterno += 1;
                                    if (nCountInterno == this.state["groupsIn"].length - 2) {
                                        var nCount = 0;
                                        let suma1 = 0;
                                        let suma2 = 0;
                                        if ("00:00" in camerasTotal) {
                                            delete camerasTotal["00:00"];
                                        }

                                        for (var key in camerasTotal) {
                                            arrayXDateChartLine.push(key);
                                            arrayX2DateChartBar.push(key);
                                            arrayYOutChartLine.push(camerasTotal[key][0]);
                                            arrayYInChartLine.push(camerasTotal[key][1]);
                                            suma1 += camerasTotal[key][1];
                                            suma2 += camerasTotal[key][0];

                                            arrayY2InChartBar.push(suma1);
                                            arrayY2OutChartBar.push(suma2);
                                            nCount += 1;
                                        }
                                        let valorTotalPersonasIn = 0;
                                        let valorTotalPersonasOut = 0;

                                        if (arrayY2InChartBar.length !== 0) {
                                            valorTotalPersonasIn = arrayY2InChartBar[arrayY2InChartBar.length - 1];
                                        } else {
                                            valorTotalPersonasIn = 0;
                                        }

                                        if (arrayY2InChartBar.length !== 0) {
                                            valorTotalPersonasOut = arrayY2OutChartBar[arrayY2OutChartBar.length - 1];
                                        } else {
                                            valorTotalPersonasOut = 0;
                                        }

                                        this.setState({
                                            valorTotalPersonasInTotal: valorTotalPersonasIn,
                                            valorTotalPersonasOutTotal: valorTotalPersonasOut,
                                        });

                                        dataTrafficIn = [valorTotalPersonasIn];
                                        dataTrafficOut = [valorTotalPersonasOut];

                                        configStateLine = {
                                            type: "line",
                                            options: {
                                                plugins: {
                                                    datalabels: {
                                                        display: true,
                                                        color: "green",
                                                        align: "end",
                                                        anchor: "end",
                                                        offset: 6,
                                                        font: { size: "14" },
                                                    },
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                title: {
                                                    display: true,
                                                    text: "Tráfico",
                                                    fontSize: 16,
                                                    fontFamily: "Gotham",
                                                    fontColor: "rgb(70, 67, 67)",
                                                },
                                                scales: {
                                                    yAxes: [
                                                        {
                                                            ticks: {
                                                                beginAtZero: true,
                                                                stepSize: 10,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            data: {
                                                labels: arrayXDateChartLine,
                                                datasets: [
                                                    {
                                                        label: ["Ingresos"],
                                                        data: arrayYOutChartLine,
                                                        backgroundColor: "transparent",
                                                        borderColor: "#86ac36",
                                                        pointStyle: "star",
                                                        pointHoverBorderWidth: 10,
                                                    },
                                                ],
                                            },
                                        };

                                        configStateBar = {
                                            type: "bar",
                                            options: {
                                                plugins: {
                                                    datalabels: {
                                                        display: true,
                                                        color: "white",
                                                        align: "end",
                                                        anchor: "center",
                                                        offset: 0,
                                                        font: { size: "14" },
                                                    },
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                title: {
                                                    display: true,
                                                    text: "¿Cuántas personas han ingresado en la última hora?",
                                                    fontSize: 16,
                                                    fontFamily: "Gotham",
                                                    fontColor: "rgb(70, 67, 67)",
                                                },
                                                scales: {
                                                    yAxes: [
                                                        {
                                                            ticks: {
                                                                beginAtZero: true,
                                                                stepSize: 100,
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            data: {
                                                labels: [
                                                    this.state.finishDateStatic.toLocaleTimeString() +
                                                        " " +
                                                        this.state.finishDateStatic.toLocaleDateString() +
                                                        " - " +
                                                        this.state.startDateStatic.toLocaleTimeString() +
                                                        " " +
                                                        this.state.finishDateStatic.toLocaleDateString(),
                                                ],
                                                datasets: [
                                                    {
                                                        label: ["Ingresos"],
                                                        data: dataTrafficOut,
                                                        backgroundColor: "#86ac36",
                                                        borderColor: "#86ac36",
                                                        borderWidth: 3,
                                                    },
                                                ],
                                            },
                                        };

                                        if (isUpdate) {
                                            let tmpLine = this.state.lineGraphic;

                                            tmpLine.data.labels = configStateLine.data.labels;
                                            tmpLine.data.datasets = configStateLine.data.datasets;
                                            tmpLine.update();
                                            let tmpBar = this.state.barGraphic;
                                            tmpBar.data.labels = configStateBar.data.labels;
                                            tmpBar.data.datasets = configStateBar.data.datasets;
                                            tmpBar.update();

                                            this.setState({
                                                lineGraphic: tmpLine,
                                                loadingStaticInLineIn: false,
                                                barGraphic: tmpBar,
                                                loadingStaticInBarIn: false,
                                            });
                                        } else {
                                            let line = document.getElementById("chartLineIn").getContext("2d");
                                            let chartLineIn = new Chart(line, configStateLine);
                                            let bar = document.getElementById("chartBarIn").getContext("2d");
                                            let chartBarIn = new Chart(bar, configStateBar);
                                            this.setState({
                                                lineGraphic: chartLineIn,
                                                loadingStaticInLineIn: false,
                                                barGraphic: chartBarIn,
                                                loadingStaticInBarIn: false,
                                            });
                                        }
                                    }
                                } else {
                                    document.getElementById("chartLineIn").style.display = "none";
                                    document.getElementById("loadingLineIn").style.display = "";
                                    document.getElementById("chartBarIn").style.display = "none";
                                    document.getElementById("loadingBarChartIn").style.display = "";
                                }
                            });
                        } else {
                            console.log("Error" + response);
                        }
                    })
                    .catch(error => console.log("error ", error));
            });
        } else {
            API.get(
                "https://dot",
                "/analytics/ingress" +
                    "?start=" +
                    finishDate +
                    "&end=" +
                    startDate +
                    "&groupId=" +
                    localStorage.getItem("idGroupIn")
            )
                .then(response => {
                    if (response.ok) {
                        response.json().then(data => {
                            if (data.length !== 0) {
                                document.getElementById("chartLineIn").style.display = "";
                                document.getElementById("loadingLineIn").style.display = "none";
                                document.getElementById("chartBarIn").style.display = "";
                                document.getElementById("loadingBarChartIn").style.display = "none";

                                data.forEach(element => {
                                    arrayXDateChartLine.push(element.event_date);
                                    arrayYOutChartLine.push(element.come_out);
                                    arrayYInChartLine.push(element.come_in);
                                });

                                let suma1 = 0;
                                let suma2 = 0;

                                data.forEach(element => {
                                    arrayX2DateChartBar.push(element.event_date);
                                    suma1 += element.come_in;
                                    suma2 += element.come_out;

                                    arrayY2InChartBar.push(suma1);
                                    arrayY2OutChartBar.push(suma2);
                                });
                            } else {
                                document.getElementById("chartLineIn").style.display = "none";
                                document.getElementById("loadingLineIn").style.display = "";
                                document.getElementById("chartBarIn").style.display = "none";
                                document.getElementById("loadingBarChartIn").style.display = "";
                            }

                            let valorTotalPersonasIn = 0;
                            let valorTotalPersonasOut = 0;

                            if (arrayY2InChartBar.length !== 0) {
                                valorTotalPersonasIn = arrayY2InChartBar[arrayY2InChartBar.length - 1];
                            } else {
                                valorTotalPersonasIn = 0;
                            }

                            if (arrayY2InChartBar.length !== 0) {
                                valorTotalPersonasOut = arrayY2OutChartBar[arrayY2OutChartBar.length - 1];
                            } else {
                                valorTotalPersonasOut = 0;
                            }

                            this.setState({
                                valorTotalPersonasInTotal: valorTotalPersonasIn,
                                valorTotalPersonasOutTotal: valorTotalPersonasOut,
                            });

                            dataTrafficIn = [valorTotalPersonasIn];
                            dataTrafficOut = [valorTotalPersonasOut];

                            configStateLine = {
                                type: "line",
                                options: {
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: "green",
                                            align: "end",
                                            anchor: "end",
                                            offset: 6,
                                            font: { size: "14" },
                                        },
                                    },
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    title: {
                                        display: true,
                                        text: "Tráfico",
                                        fontSize: 16,
                                        fontFamily: "Gotham",
                                        fontColor: "rgb(70, 67, 67)",
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                    stepSize: 10,
                                                },
                                            },
                                        ],
                                    },
                                },
                                data: {
                                    labels: arrayXDateChartLine,
                                    datasets: [
                                        {
                                            label: ["Ingresos"],
                                            data: arrayYOutChartLine,
                                            backgroundColor: "transparent",
                                            borderColor: "#86ac36",
                                            pointStyle: "star",
                                            pointHoverBorderWidth: 10,
                                        },
                                    ],
                                },
                            };

                            configStateBar = {
                                type: "bar",
                                options: {
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: "white",
                                            align: "end",
                                            anchor: "center",
                                            offset: 6,
                                            font: { size: "14" },
                                        },
                                    },
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    title: {
                                        display: true,
                                        text: "¿Cuántas personas han ingresado en la última hora?",
                                        fontSize: 16,
                                        fontFamily: "Gotham",
                                        fontColor: "rgb(70, 67, 67)",
                                    },
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    beginAtZero: true,
                                                    stepSize: 100,
                                                },
                                            },
                                        ],
                                    },
                                },
                                data: {
                                    labels: [
                                        this.state.finishDateStatic.toLocaleTimeString() +
                                            " " +
                                            this.state.finishDateStatic.toLocaleDateString() +
                                            " - " +
                                            this.state.startDateStatic.toLocaleTimeString() +
                                            " " +
                                            this.state.finishDateStatic.toLocaleDateString(),
                                    ],
                                    datasets: [
                                        {
                                            label: ["Ingresos"],
                                            data: dataTrafficOut,
                                            backgroundColor: "#86ac36",
                                            borderColor: "#86ac36",
                                            borderWidth: 3,
                                        },
                                    ],
                                },
                            };

                            if (isUpdate) {
                                let tmpLine = this.state.lineGraphic;
                                if ("labels" in configStateLine.data) {
                                    let line = document.getElementById("chartLineIn").getContext("2d");
                                    let chartLineIn = new Chart(line, configStateLine);
                                    let bar = document.getElementById("chartBarIn").getContext("2d");
                                    let chartBarIn = new Chart(bar, configStateBar);

                                    this.setState({
                                        lineGraphic: chartLineIn,
                                        loadingStaticInLineIn: false,
                                        barGraphic: chartBarIn,
                                        loadingStaticInBarIn: false,
                                    });
                                } else {
                                    tmpLine.data.labels = configStateLine.data.labels;
                                    tmpLine.data.datasets = configStateLine.data.datasets;
                                    tmpLine.update();
                                    let tmpBar = this.state.barGraphic;
                                    tmpBar.data.labels = configStateBar.data.labels;
                                    tmpBar.data.datasets = configStateBar.data.datasets;
                                    tmpBar.update();

                                    this.setState({
                                        lineGraphic: tmpLine,
                                        loadingStaticInLineIn: false,
                                        barGraphic: tmpBar,
                                        loadingStaticInBarIn: false,
                                    });
                                }
                            } else {
                                let line = document.getElementById("chartLineIn").getContext("2d");
                                let chartLineIn = new Chart(line, configStateLine);
                                let bar = document.getElementById("chartBarIn").getContext("2d");
                                let chartBarIn = new Chart(bar, configStateBar);

                                this.setState({
                                    lineGraphic: chartLineIn,
                                    loadingStaticInLineIn: false,
                                    barGraphic: chartBarIn,
                                    loadingStaticInBarIn: false,
                                });
                            }
                        });
                    } else {
                        console.log("Error" + response);
                    }
                })
                .catch(error => console.log("error ", error));
        }
    }

    showGraphicsZones(id, name, isUpdate, startDate, finishDate) {
        this.getAforo("idGroupZones");

        let arrayXDateChartLine = [];
        let arrayYOutChartLine = [];
        let arrayYInChartLine = [];
        let arrayX2DateChartBar = [];
        let arrayY2InChartBar = [];
        let arrayY2OutChartBar = [];
        let dataTrafficIn = [];
        let dataTrafficOut = [];
        let arrayYGenderW = [];
        let arrayYGenderM = [];
        let arrayYGenderG = [];
        let arrayYGenderB = [];
        let configStateLine;
        let configStateBar;
        let configStateBar_2;

        this.setState({
            loadingStaticInLineZones: true,
            loadingStaticInBarZones: true,
            loadingStaticGender: true,
        });

        API.get(
            "https://dot",
            "/analytics/ingress" +
                "?start=" +
                finishDate +
                "&end=" +
                startDate +
                "&groupId=" +
                localStorage.getItem("idGroupZones")
        )
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.length !== 0) {
                            document.getElementById("chartLineZones").style.display = "";
                            document.getElementById("loadingLineZones").style.display = "none";
                            document.getElementById("chartBarZones").style.display = "";
                            document.getElementById("loadingBarChartZones").style.display = "none";

                            data.forEach(element => {
                                arrayXDateChartLine.push(element.event_date);
                                arrayYOutChartLine.push({
                                    x: data.indexOf(element),
                                    y: element.come_out,
                                });
                                arrayYInChartLine.push({
                                    x: data.indexOf(element),
                                    y: element.come_in,
                                });
                            });

                            let suma1 = 0;
                            let suma2 = 0;

                            data.forEach(element => {
                                arrayX2DateChartBar.push(element.event_date);
                                suma1 += element.come_in;
                                suma2 += element.come_out;

                                arrayY2InChartBar.push({
                                    x: data.indexOf(element),
                                    y: suma1,
                                });
                                arrayY2OutChartBar.push({
                                    x: data.indexOf(element),
                                    y: suma2,
                                });
                            });
                        } else {
                            document.getElementById("chartLineZones").style.display = "none";
                            document.getElementById("loadingLineZones").style.display = "";
                            document.getElementById("chartBarZones").style.display = "none";
                            document.getElementById("loadingBarChartZones").style.display = "";
                        }
                        let valorTotalPersonasIn = 0;
                        let valorTotalPersonasOut = 0;

                        if (arrayY2InChartBar.length !== 0) {
                            valorTotalPersonasIn = arrayY2InChartBar[arrayY2InChartBar.length - 1].y;
                        } else {
                            valorTotalPersonasIn = 0;
                        }

                        if (arrayY2InChartBar.length !== 0) {
                            valorTotalPersonasOut = arrayY2OutChartBar[arrayY2OutChartBar.length - 1].y;
                        } else {
                            valorTotalPersonasOut = 0;
                        }
                        let totalPersonasZones = Math.abs(valorTotalPersonasIn) + Math.abs(valorTotalPersonasOut);
                        this.setState({ valorTotalPersonasTotalZones: totalPersonasZones });

                        dataTrafficIn = [
                            {
                                x: 1,
                                y: valorTotalPersonasIn,
                            },
                        ];
                        dataTrafficOut = [
                            {
                                x: 1,
                                y: valorTotalPersonasOut,
                            },
                        ];

                        configStateLine = {
                            type: "line",
                            options: {
                                maintainAspectRatio: false,
                                responsive: true,
                                title: {
                                    display: true,
                                    text: "Tráfico",
                                    fontSize: 16,
                                    fontFamily: "Gotham",
                                    fontColor: "rgb(70, 67, 67)",
                                },
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 10,
                                            },
                                        },
                                    ],
                                },
                            },
                            data: {
                                labels: arrayXDateChartLine,
                                datasets: [
                                    {
                                        label: ["Ingresos"],
                                        data: arrayYOutChartLine,
                                        backgroundColor: "transparent",
                                        borderColor: "#86ac36",
                                        pointStyle: "star",
                                        pointHoverBorderWidth: 10,
                                    },
                                ],
                            },
                        };

                        configStateBar = {
                            type: "bar",
                            options: {
                                plugins: {
                                    datalabels: {
                                        display: true,
                                        color: "white",
                                        anchor: "center",
                                        font: { size: "14" },
                                    },
                                },
                                maintainAspectRatio: false,
                                responsive: true,
                                title: {
                                    display: true,
                                    text: "¿Cuántas personas han ingresado en la última hora?",
                                    fontSize: 16,
                                    fontFamily: "Gotham",
                                    fontColor: "rgb(70, 67, 67)",
                                },
                                scales: {
                                    yAxes: [
                                        {
                                            ticks: {
                                                beginAtZero: true,
                                                stepSize: 100,
                                            },
                                        },
                                    ],
                                },
                            },
                            data: {
                                labels: [
                                    this.state.finishDateStatic.toLocaleTimeString() +
                                        " " +
                                        this.state.finishDateStatic.toLocaleDateString() +
                                        " - " +
                                        this.state.startDateStatic.toLocaleTimeString() +
                                        " " +
                                        this.state.finishDateStatic.toLocaleDateString(),
                                ],
                                datasets: [
                                    {
                                        label: ["Ingresos"],
                                        data: dataTrafficOut,
                                        backgroundColor: "#86ac36",
                                        borderColor: "#86ac36",
                                        borderWidth: 3,
                                    },
                                ],
                            },
                        };

                        if (isUpdate) {
                            let tmpLine = this.state.lineGraphic_2;
                            tmpLine.data.labels = configStateLine.data.labels;
                            tmpLine.data.datasets = configStateLine.data.datasets;
                            tmpLine.update();
                            let tmpBar = this.state.barGraphic_2;
                            tmpBar.data.labels = configStateBar.data.labels;
                            tmpBar.data.datasets = configStateBar.data.datasets;
                            tmpBar.update();

                            this.setState({
                                lineGraphic_2: tmpLine,
                                loadingStaticInLineZones: false,
                                barGraphic_2: tmpBar,
                                loadingStaticInBarZones: false,
                            });
                        } else {
                            let line = document.getElementById("chartLineZones").getContext("2d");
                            let chartLineZones = new Chart(line, configStateLine);
                            let bar = document.getElementById("chartBarZones").getContext("2d");
                            let chartBarZones = new Chart(bar, configStateBar);

                            this.setState({
                                lineGraphic_2: chartLineZones,
                                loadingStaticInLineZones: false,
                                barGraphic_2: chartBarZones,
                                loadingStaticInBarZones: false,
                            });
                        }
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log("error ", error));
    }

    async showUpdates() {
        try {
            const res = await API.get("https://dot", "/general");
            let js = await res.json();
            let arry = [];
            if (!Object.keys(js).includes("message")) {
                js.forEach(element => {
                    const url = element.url_tuto;
                    arry.push(
                        <div className="Card">
                            <div className="contain_update" key={js.indexOf(element)}>
                                <label className="Title-Update">{element.description} </label>
                                <label className="Text-Update">
                                    <img src={iconHand} alt="Icono noticia" width="26rem"></img>{" "}
                                    <a
                                        className="Link-Update"
                                        href={"http://www." + url}
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                    >
                                        {element.url_tuto}
                                    </a>
                                </label>
                            </div>
                        </div>
                    );
                });
            } else {
                arry.push(
                    <div className="contain_update-none">
                        <strong>{js.message}</strong>
                    </div>
                );
            }
            this.setState({
                updates: arry,
            });
        } catch (error) {
            return error;
        }
    }

    changeToIn = () => {
        document.getElementById("aforoGeneral").style.display = "none";
        document.getElementById("InDashboard").style.display = "";
        document.getElementById("ZonesDashboard").style.display = "none";
        document.getElementById("buttonInDashboard").style.backgroundColor = "#84AC3490";
        document.getElementById("buttonZonesDashboard").style.backgroundColor = "#dae0e5";
        document.getElementById("buttonAforoGeneral").style.backgroundColor = "#dae0e5";
        this.getListCamerasIn();
        this.updateDashboardIn(
            localStorage.getItem("idGroupIn"),
            localStorage.getItem("nameGroupIn"),
            true,
            new Date().getTime(),
            new Date().getTime() - 1 * 3600 * 1000
        );
    };

    changeToZones = () => {
        document.getElementById("InDashboard").style.display = "none";
        document.getElementById("ZonesDashboard").style.display = "";
        document.getElementById("aforoGeneral").style.display = "none";
        document.getElementById("buttonZonesDashboard").style.backgroundColor = "#84AC3490";
        document.getElementById("buttonInDashboard").style.backgroundColor = "#dae0e5";
        document.getElementById("buttonAforoGeneral").style.backgroundColor = "#dae0e5";
        this.getListCamerasZones();
        this.updateDashboardZones(
            localStorage.getItem("idGroupZones"),
            localStorage.getItem("nameGroupZones"),
            true,
            new Date().getTime(),
            new Date().getTime() - 1 * 3600 * 1000
        );
    };

    changeToAforoGeneral = () => {
        document.getElementById("InDashboard").style.display = "none";
        document.getElementById("aforoGeneral").style.display = "";
        document.getElementById("ZonesDashboard").style.display = "none";
        document.getElementById("buttonZonesDashboard").style.backgroundColor = "#dae0e5";
        document.getElementById("buttonAforoGeneral").style.backgroundColor = "#84AC3490";
        document.getElementById("buttonInDashboard").style.backgroundColor = "#dae0e5";
    };

    componentDidMount() {
        document.title = "Switch AI | Dashboard";
        this.getListGroupsIn();

        this.intervalIdIn = setInterval(
            () =>
                this.updateDashboardIn(
                    localStorage.getItem("idGroupIn"),
                    localStorage.getItem("nameGroupIn"),
                    true,
                    new Date().getTime(),
                    new Date().getTime() - 1 * 3600 * 1000
                ),
            300000
        );

        this.getListGroupsZones();
        this.intervalIdZones = setInterval(
            () =>
                this.updateDashboardZones(
                    localStorage.getItem("idGroupZones"),
                    localStorage.getItem("nameGroupZones"),
                    true,
                    new Date().getTime(),
                    new Date().getTime() - 1 * 3600 * 1000
                ),
            300000
        );

        if (localStorage["Email"] === COLINA_EMAIL) {
            document.getElementById("InDashboard").style.display = "";
            document.getElementById("aforoGeneral").style.display = "none";
            document.getElementById("ZonesDashboard").style.display = "none";
            document.getElementById("buttonZonesDashboard").style.backgroundColor = "#dae0e5";
            document.getElementById("buttonAforoGeneral").style.backgroundColor = "#dae0e5";
            document.getElementById("buttonInDashboard").style.backgroundColor = "#84AC3490";
        } else {
            document.getElementById("InDashboard").style.display = "none";
            document.getElementById("aforoGeneral").style.display = "";
            document.getElementById("ZonesDashboard").style.display = "none";
            document.getElementById("buttonZonesDashboard").style.backgroundColor = "#dae0e5";
            document.getElementById("buttonAforoGeneral").style.backgroundColor = "#84AC3490";
            document.getElementById("buttonInDashboard").style.backgroundColor = "#dae0e5";
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalIdIn);
        clearInterval(this.intervalIdZones);
    }

    hideObjectsIn() {
        document.getElementById("loadingBarChartIn").style.display = "none";
        document.getElementById("loadingLineIn").style.display = "none";
    }

    hideObjectsZones() {
        document.getElementById("loadingBarChartZones").style.display = "none";
        document.getElementById("loadingLineZones").style.display = "none";
    }

    closeSideBar() {
        document.getElementById("lateralMenu").style.display = "none";
        document.getElementById("genericBody").style.filter = "none";
        document.getElementById("sub-menu").style.display = "none";
        document.getElementById("menuIcon2").style.display = "none";
        document.getElementById("menuIcon1").style.display = "";
        document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
    }

    render() {
        const isColina = localStorage["Email"] === COLINA_EMAIL;

        return (
            <div className="marketing">
                <NavMenuBar></NavMenuBar>
                <div id="genericBody" onClick={this.closeSideBar}>
                    <Row>
                        <Col lg={12}>
                            <Row>
                                <Col lg={12}>
                                    <div>
                                        <div className="Body-Dashboard" id="Body-Dashboard">
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="Line-Separator-Menu">
                                                        <Button
                                                            id="buttonAforoGeneral"
                                                            variant="light"
                                                            className={`Button-Change-Dash ${isColina ? "invisible" : ""}`}
                                                            onClick={this.changeToAforoGeneral}
                                                        >
                                                            Aforo General
                                                        </Button>

                                                        <Button
                                                            id="buttonInDashboard"
                                                            variant="light"
                                                            className="Button-Change-Dash"
                                                            onClick={this.changeToIn}
                                                        >
                                                            Accesos
                                                        </Button>

                                                        <Button
                                                            id="buttonZonesDashboard"
                                                            variant="light"
                                                            className="Button-Change-Dash"
                                                            onClick={this.changeToZones}
                                                        >
                                                            Zonas
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div id="InDashboard">
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="form-inline">
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="light"
                                                                    id="dropdown-basic"
                                                                    className="Camera-Button"
                                                                >
                                                                    Grupo {localStorage.getItem("nameGroupIn")}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>{this.state.groupsIn}</Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="Container-Layout">
                                                                    <div className="Load">
                                                                        {this.state.loadingStaticInLineIn && (
                                                                            <div className="loader"></div>
                                                                        )}
                                                                    </div>
                                                                    <canvas id="chartLineIn"></canvas>
                                                                    <div id="loadingLineIn">
                                                                        <div className="Div-Center">
                                                                            <label className="Title-Message">Tráfico</label>
                                                                            <br></br>
                                                                            <br></br>
                                                                            <img
                                                                                src={working}
                                                                                width="80rem"
                                                                                alt="Imagen de Loading"
                                                                            ></img>
                                                                            <br></br>
                                                                            <br></br>
                                                                            <label className="Text-Message">
                                                                                Estamos procesando tus datos
                                                                            </label>
                                                                            <br></br>
                                                                            <label className="Text-Message">
                                                                                Pronto tendremos estadísticas para ti
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="Container-Layout">
                                                                    <div className="Load">
                                                                        {this.state.loadingStaticInBarIn && (
                                                                            <div className="loader"></div>
                                                                        )}
                                                                    </div>
                                                                    <canvas id="chartBarIn"></canvas>
                                                                    <div id="loadingBarChartIn" className="Div-Center">
                                                                        <label className="Title-Message">
                                                                            Histórico de ingreso y salida
                                                                        </label>
                                                                        <br></br>
                                                                        <br></br>
                                                                        <img
                                                                            src={working}
                                                                            width="80rem"
                                                                            alt="Imagen de Loading"
                                                                        ></img>
                                                                        <br></br>
                                                                        <br></br>
                                                                        <label className="Text-Message">
                                                                            Estamos procesando tus datos
                                                                        </label>
                                                                        <br></br>
                                                                        <label className="Text-Message">
                                                                            Pronto tendremos estadísticas para ti
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <div className="Container-Layout marketing__card">
                                                            <div className="Load">
                                                                {this.state.loadingAforo && <div className="loader"></div>}
                                                            </div>
                                                            <div className="Div-Center">
                                                                <label className="Title-Aforo marketing__card-title">
                                                                    En la última hora <b>Salieron</b>
                                                                </label>
                                                                <br></br>
                                                                <label className="Capacity marketing__card-value">
                                                                    {this.state.valorTotalPersonasInTotal}
                                                                </label>
                                                                <br></br>
                                                                <label className="Title-Aforo marketing__card-title">
                                                                    personas por este acceso.{" "}
                                                                </label>
                                                                <br></br>
                                                                <Clock
                                                                    format={"h:mm:ss a"}
                                                                    ticking={true}
                                                                    timezone={"America/Bogota"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <div className="Container-Layout marketing__card">
                                                            <div className="Load">
                                                                {this.state.loadingAforo && <div className="loader"></div>}
                                                            </div>
                                                            <div className="Div-Center">
                                                                <label className="Title-Aforo marketing__card-title">
                                                                    En la última hora <b>Ingresaron</b>
                                                                </label>
                                                                <br></br>
                                                                <label className="Capacity marketing__card-value">
                                                                    {this.state.valorTotalPersonasOutTotal}
                                                                </label>
                                                                <br></br>
                                                                <label className="Title-Aforo marketing__card-title">
                                                                    personas por esta zona.{" "}
                                                                </label>
                                                                <br></br>
                                                                <Clock
                                                                    format={"h:mm:ss a"}
                                                                    ticking={true}
                                                                    timezone={"America/Bogota"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div id="ZonesDashboard">
                                                <Row>
                                                    <Col lg={12}>
                                                        <div className="form-inline">
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="light"
                                                                    id="dropdown-basic"
                                                                    className="Camera-Button"
                                                                >
                                                                    Grupo {localStorage.getItem("nameGroupZones")}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>{this.state.groupsZones}</Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="Container-Layout">
                                                                    <div className="Load">
                                                                        {this.state.loadingStaticInLineZones && (
                                                                            <div className="loader"></div>
                                                                        )}
                                                                    </div>
                                                                    <canvas id="chartLineZones"></canvas>
                                                                    <div id="loadingLineZones">
                                                                        <div className="Div-Center">
                                                                            <label className="Title-Message">Tráfico</label>
                                                                            <br></br>
                                                                            <br></br>
                                                                            <img
                                                                                src={working}
                                                                                width="80rem"
                                                                                alt="Imagen de Loading"
                                                                            ></img>
                                                                            <br></br>
                                                                            <br></br>
                                                                            <label className="Text-Message">
                                                                                Estamos procesando tus datos
                                                                            </label>
                                                                            <br></br>
                                                                            <label className="Text-Message">
                                                                                Pronto tendremos estadísticas para ti
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={7}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="Container-Layout">
                                                                    <div className="Load">
                                                                        {this.state.loadingStaticInBarZones && (
                                                                            <div className="loader"></div>
                                                                        )}
                                                                    </div>
                                                                    <canvas id="chartBarZones"></canvas>
                                                                    <div id="loadingBarChartZones" className="Div-Center">
                                                                        <label className="Title-Message">
                                                                            Histórico de trafico ingreso y salida
                                                                        </label>
                                                                        <br></br>
                                                                        <br></br>
                                                                        <img
                                                                            src={working}
                                                                            width="80rem"
                                                                            alt="Imagen de Loading"
                                                                        ></img>
                                                                        <br></br>
                                                                        <br></br>
                                                                        <label className="Text-Message">
                                                                            Estamos procesando tus datos
                                                                        </label>
                                                                        <br></br>
                                                                        <label className="Text-Message">
                                                                            Pronto tendremos estadísticas para ti
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={5}>
                                                        <div className="Container-Layout">
                                                            <div className="Load">
                                                                {this.state.loadingAforo && <div className="loader"></div>}
                                                            </div>
                                                            <div className="Div-Center">
                                                                <label className="Title-Aforo">
                                                                    En la última hora <b>Transitaron</b>
                                                                </label>
                                                                <br></br>
                                                                <label className="Capacity">
                                                                    {this.state.valorTotalPersonasTotalZones}
                                                                </label>
                                                                <br></br>
                                                                <label className="Title-Aforo">personas por este acceso. </label>
                                                                <br></br>
                                                                <Clock
                                                                    format={"h:mm:ss a"}
                                                                    ticking={true}
                                                                    timezone={"America/Bogota"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div id="aforoGeneral">
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="Container-Layout">
                                                            <div className="Load">
                                                                {this.state.loadingAforoGeneral && <div className="loader"></div>}
                                                            </div>
                                                            <div className="Div-Center">
                                                                <label className="Title-Aforo">En este momento </label>
                                                                <br></br>
                                                                <label className="Capacity">{this.state.capacityGeneral}</label>
                                                                <br></br>
                                                                <label className="Title-Aforo">
                                                                    personas en tu establecimiento{" "}
                                                                </label>
                                                                <br></br>
                                                                <br></br>

                                                                <Clock
                                                                    format={"h:mm:ss a"}
                                                                    ticking={true}
                                                                    timezone={"America/Bogota"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="Container-Layout">
                                                            <div className="Load">
                                                                {this.state.loadingAforoGeneral && <div className="loader"></div>}
                                                            </div>
                                                            <div className="Div-Center">
                                                                <label className="Title-Aforo">Promedio de aforo </label>
                                                                <br></br>
                                                                <br></br>
                                                                <label className="Capacity">
                                                                    {this.state.porcentajeCapacity}
                                                                </label>

                                                                <label className="Title-Aforo">% </label>
                                                                <br></br>

                                                                <img
                                                                    src={this.state.tempLogo}
                                                                    width="250rem"
                                                                    alt="Imagen de Loading"
                                                                ></img>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Footer></Footer>
                </div>
            </div>
        );
    }
}

export default DashboardSuper;
