import { useCallback, useEffect, useMemo, useState } from "react";
import API from "api/Main";
import { addOneDay } from "pages/Schedule";
import { dateToUnix, formatDate } from "utils/Date";
import { getCapacityPeaks } from "utils/Traffic";

const formatIncomeData = data => Object.entries(data).map(([date, value]) => ({ date, value }));

export const useTraffic = ({ filters, formattedDate, filterByDays }) => {
    const [traffic, setTraffic] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const { date, cameras } = filters;

    const getTrafficUrl = useCallback(
        id => {
            if (filterByDays) {
                return `/analytics/resume-traffic/?groupId=${id}&start=${formattedDate.start}&end=${addOneDay(
                    formattedDate.end
                )}`;
            }
            const [startDate, endDate] = [getHour(date, true), getHour(date, false)];
            return `/analytics/ingress?start=${startDate}&end=${endDate}&groupId=${id}`;
        },
        [date, filterByDays, formattedDate]
    );

    const getTraffic = useCallback(async () => {
        if (cameras.length && formattedDate.start) {
            setShowLoader(true);
            if (!filterByDays && getHour(date, true) === getHour(date, false)) return;
            const traffic = await Promise.all(
                cameras.map(({ id }) => API.get("https://dot", getTrafficUrl(id)).then(res => res.json()))
            );
            const hasErrors = traffic.some(item => item?.errors);
            if (!hasErrors) setTraffic(traffic);
            setShowLoader(false);
        }
    }, [getTrafficUrl, cameras, formattedDate, filters.period]);

    const getIncomeData = useCallback(() => {
        const total = { comeIn: {}, comeOut: {} };
        traffic.flat().forEach(item => {
            const date = item?.fecha ? formatDate(item.fecha) : item?.event_date;
            total.comeIn[date] = (total.comeIn[date] ?? 0) + item.come_in;
            total.comeOut[date] = (total.comeOut[date] ?? 0) + item.come_out;
        });
        return {
            comeIn: formatIncomeData(total.comeIn),
            comeOut: formatIncomeData(total.comeOut),
        };
    }, [traffic]);

    useEffect(() => {
        getTraffic();
    }, [getTraffic]);

    const { comeOut: incomeData } = useMemo(() => getIncomeData(), [getIncomeData]);

    const capacityPeaks = useMemo(() => getCapacityPeaks(incomeData), [incomeData]);

    return {
        capacityPeaks,
        incomeData,
        showLoader,
        traffic,
    };
};

const getHour = (date, isStart) => {
    const currentDate = new Date(date.day);
    currentDate.setHours(date[isStart ? "start" : "end"].getHours(), 0, 0);
    return dateToUnix(currentDate);
};
