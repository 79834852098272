export * from "./WeeklyReport";

export const PERIOD_OPTIONS = [
    {
        value: "Semanal",
        type: "weekly",
    },
    {
        value: "Mensual",
        type: "monthly",
    },
    {
        value: "Trimestral",
        type: "quarterly",
    },
    {
        value: "Semestral",
        type: "biannual",
    },
    {
        value: "Anual",
        type: "annual",
    },
];

export const PERIOD_DAYS = {};

export const INCOME_DATA = [
    {
        date: "2024-05-07",
        value: 6510,
    },
    {
        date: "2024-05-08",
        value: 6259,
    },
    {
        date: "2024-05-09",
        value: 5424,
    },
    {
        date: "2024-05-10",
        value: 7159,
    },
    {
        date: "2024-05-11",
        value: 6504,
    },
    {
        date: "2024-05-12",
        value: 5474,
    },
    {
        date: "2024-05-13",
        value: 5311,
    },
];

export const CHART_DATA = [
    {
        name: "Entrada del parque",
        total: 15885,
    },
    {
        name: "Entrada Colpatria",
        total: 8063,
    },
    {
        name: "Entrada Calle 138",
        total: 5738,
    },
    {
        name: "Entrada Carulla",
        total: 12955,
    },
];
