import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { WeeklyReport } from "./components";
import NavMenuBar from "components/NavMenuBar";
import { closeSideBar } from "utils/Navbar";
import "./Reports.scss";

const Reports = () => {
    return (
        <div className="reports">
            <NavMenuBar />
            <main className="reports__content" id="genericBody" onClick={closeSideBar}>
                <>
                    <WeeklyReport />
                    <div className="reports__buttons">
                        <Button className="close-button">
                            Atrás
                            <i className="fa-solid fa-rotate-left" />
                        </Button>
                    </div>
                </>
            </main>
        </div>
    );
};

export default Reports;
