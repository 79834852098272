import { useEffect, useState, useMemo } from "react";
import API from "api/Main";
import { createCameraOptions } from "pages/Schedule";

export const useCameras = () => {
    const [cameras, setCameras] = useState([]);

    const cameraOptions = useMemo(() => createCameraOptions(cameras), [cameras]);

    useEffect(() => {
        getCameras();
    }, []);

    const getCameras = async () => {
        const response = await API.get("https://dot", "/resource_group_specific/?model=ML-D11");
        if (response.ok) setCameras(await response.json());
    };

    return {
        cameraOptions,
    };
};
