import React, { useMemo, useState } from "react";
import { DateInput } from "components/DateInput";
import { Select } from "components/Select";
import { addDays } from "utils/Date";
import { createOptions } from "utils/Select";
import pdf from "imgs/pdf.svg";
import { PERIOD_OPTIONS, CHART_DATA, INCOME_DATA } from ".";

// clear
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { addThousands } from "utils/Value";
import { BarChart } from "components/BarChart";
import { PieChart } from "components/PieChart";

export const WeeklyReport = () => {
    const periodOptions = useMemo(() => createOptions(PERIOD_OPTIONS), []);
    const [date, setDate] = useState({ start: new Date(moment().startOf("isoweek").utc()), end: new Date() });
    const [period, setPeriod] = useState(periodOptions[0]);

    const printDocument = () => {
        const input = document.getElementById("pdfContent");
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL("image/svg");
            const pdf = new jsPDF({
                orientation: "landscape",
                format: [235, 120],
            });
            pdf.addImage(imgData, "SVG", 0, 0);
            pdf.save("informe.pdf");
        });
    };

    return (
        <div className="report">
            <h1 className="report__title">Informe</h1>
            <div className="report__header">
                <div className="report__filters">
                    <Select
                        className="report__select"
                        options={periodOptions}
                        value={period}
                        handleChange={option => setPeriod(option)}
                        label="Periodo:"
                    />
                    <div className="report__dates">
                        <DateInput
                            handleChange={date => setDate({ ...date, start: date })}
                            value={date.start}
                            label="Fecha inicial:"
                            filterDate={date => date.getDay() === 1}
                        />
                        <DateInput disabled value={addDays(date.start, 6)} label="Fecha final:" />
                    </div>
                </div>
                <button className="report__pdf-button">
                    <img alt="Download pdf" src={pdf} onClick={printDocument} />
                    Descargar
                </button>
            </div>
            <br />
            <div className="report__content" id="pdfContent">
                <h2 className="report__total--value">{addThousands(128000)}</h2>
                <p className="report__total--text">Total de visitantes</p>
                <div className="report__charts">
                    <BarChart
                        data={INCOME_DATA}
                        settings={{ barName: "Ingresos", dataKey: "value", xKey: "date", width: "70%" }}
                    />
                    <PieChart data={CHART_DATA} settings={{ width: 200, height: 300 }} />
                </div>
            </div>
        </div>
    );
};
