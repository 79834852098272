//Import main libraries and components
import React from "react";
import NavMenuBar from "../../components/NavMenuBar";
//import NavMenuBar from "../../components/NavMenu";
import API from "../../api/Main";
import { Row, Col, Dropdown } from "react-bootstrap";
import { Chart } from "chart.js";
import Footer from "../../components/Footer";
import Cookies from "universal-cookie";
import Schedules from "../../utils/HoursConfiguration";
import "@nivo/core";
import "@nivo/calendar";
import { ResponsiveCalendarCanvas } from "@nivo/calendar";
import ProgressBar from "react-bootstrap/ProgressBar";
import "chartjs-plugin-datalabels";
import { StackedBarChart } from "components/StackedBarChart";
import { COLINA_EMAIL } from "constants/Customer";
import { getCharacterizationData } from ".";

//import { ResponsiveCalendarCanvas, ResponsiveCalendar , Calendar} from '@nivo/calendar'

//Import images
import working from "../../imgs/Recurso10.png";

//Import styles
import "../../styles/home.css";

const _ = require("underscore");

class Home extends React.Component {
    utils = new Schedules();
    constructor(props) {
        let today = new Date(),
            date = today.getFullYear(),
            firstDay = new Date(new Date().getFullYear(), 0, 1),
            firstDayMilium = firstDay.getTime() / 1000;

        super(props);
        this.state = {
            dateFrom: "",
            dateTo: "",
            firstDayYear: firstDayMilium,
            year: date,
            women: 0,
            men: 0,
            groups: [],
            valueLabel4: 0,
            valueLabel1: 0,
            valueLabel3: 0,
            valueLabel2: 0,
            tempId: "",
            tempName: "",
            progress: 0.0,
            datosTotales: 0,
            startDate: new Date(),
            finishDate: new Date(new Date().getTime() - 604800000),
            middleNight: new Date().setHours(0, 0, 0, 0),
            trafic: [],
            data: [],
            loadingStaticInBar: false,
            loadingStaticHistoric: false,
            loadingCalendar: false,
            barGraphic: {},
            barGraphicHistoric: {},
            capacity: "",
            loading: true,
            pagination: 1,
            counter: 0,
            cameras: [],
            dataCalendar: [],
            typeAlgorithm: [],
            specificMonth: [],
            valueLabel5: "",
            valueLabel6: "",
            capacityGeneral: "",
            capacityTotal: "50000",
            porcentajeCapacity: "",
            label1: "Total de visitantes que han ingresado durante el mes seleccionado",
            label2: "Visitantes que han ingresado hoy",
            label3: "Total de visitantes que han ingresado durante la semana seleccionada",
            label4: "Visitantes hace una semana",
            label5: "Día más visitado",
            label6: "Día menos visitado",
            dataType: "",
            characterizationData: {},
        };
    }

    handleClickCamera = e => {
        this.setState({
            loading: true,
            tempId: e.target.id,
            tempName: e.target.name,
        });
        this.updateDashboardStatic(
            e.target.id,
            e.target.name,
            true,
            new Date().getTime(),
            new Date().getTime() - 1 * 3600 * 1000
        );
        this.getAforo();
    };

    getListCameras(pagination) {
        API.get("https://dot", "/cameras/" + pagination)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.length !== 0) {
                            data.forEach(element => {
                                let item = (
                                    <Dropdown.Item
                                        onClick={this.handleClickCamera}
                                        className="Camera"
                                        id={element.id}
                                        key={element.id}
                                        name={element.name}
                                    >
                                        {element.name}
                                    </Dropdown.Item>
                                );
                                this.state.cameras.push(item);
                            });

                            this.setState({
                                loading: false,
                                tempId: this.state.cameras[0].key,
                                tempName: this.state.cameras[0].props.name,
                            });
                            this.updateDashboardStatic(
                                this.state.cameras[0].key,
                                this.state.cameras[0].props.name,
                                false,
                                new Date().getTime(),
                                new Date().getTime() - 1 * 3600 * 1000
                            );
                        } else {
                            console.log("¿Porque me inspeccionas?");
                        }
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    updateDashboardStatic(id, name, isUpdate, startDate, finishDate) {
        this.showGraphicsStatic(id, isUpdate, startDate, finishDate);
    }

    showGraphicsStatic(id, isUpdate, startDate, finishDate) {
        this.getAforo();
        this.getAforoGeneral();

        let arrayWeeksMonth = [];

        let arrayY2InChartBarPets = [];
        let arrayY2InChartBar = [];
        let arrayY2InChartBarMen = [];
        let arrayY2InChartBarWomen = [];
        let arrayX2DateChartBar = [];
        let arrayXDateCapacity = [];
        let arrayDates = [];
        let configStateBar;
        let configStateBarHistoric;
        //let startDateTrafic = new Date().getTime() - (30 * 24 * 60 * 60 * 1000);
        var date = new Date();
        let startDateTrafic = new Date(date.getFullYear(), date.getMonth(), 1);
        let NumberWomen = 0;
        let NumberMen = 0;
        let NumberPeopleOnWeek = 0;
        let NumberPeopleOnCompleteWeek = 0;
        let NumberPeopleOnWeekWoman = 0;

        let NumberPeopleToday = 0;
        let NumberPeopleTodayOut = 0;
        let startDateCalendar = new Date(new Date().getFullYear(), 0, 1);
        let finishDateCalendar = new Date(new Date().getTime());
        let formatstartDateTrafic = new Date(startDateTrafic);
        let startDateFormat = `${startDateCalendar.getFullYear()}-${
            startDateCalendar.getMonth() + 1
        }-${startDateCalendar.getDate()}`;
        let finishDateFormat = `${finishDateCalendar.getFullYear()}-${
            finishDateCalendar.getMonth() + 1
        }-${finishDateCalendar.getDate()}`;
        let dateMax;
        let dateMin;
        let dateMaxWoman;
        let dateMinWoman;
        this.setState({ loadingCalendar: true, loadingStaticInBar: true, loadingStaticHistoric: true });

        if (localStorage.getItem("specificMonth") == "Mes Anterior" || date.getDate() == 1) {
            startDateTrafic = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            formatstartDateTrafic = new Date(startDateTrafic);
            let daysInMonth = new Date(finishDateCalendar.getFullYear(), finishDateCalendar.getMonth(), 0).getDate();
            if (daysInMonth == 31) {
                finishDateFormat = `${finishDateCalendar.getFullYear()}-${finishDateCalendar.getMonth() + 1}-${1}`;
            } else {
                finishDateFormat = `${finishDateCalendar.getFullYear()}-${finishDateCalendar.getMonth()}-${daysInMonth + 1}`;
            }
        }

        API.get("https://dot", "/analytics/gender" + "?start=" + finishDate + "&end=" + startDate)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        if (data.length !== 0) {
                            NumberWomen = NumberWomen + data.women;
                            NumberMen = data.men + NumberMen;
                            this.setState({ women: NumberWomen, men: NumberMen, loading: false });
                        } else {
                            console.log("Error" + response);
                        }
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log("error ", error));

        if (localStorage.getItem("idAlgorithm") == "traffic") {
            //characterization
            this.setState({
                label1: "Total de visitantes que han ingresado durante este mes",
                label2: "Visitantes que han ingresado hoy",
                label3: "Total de visitantes que han ingresado durante la última semana",
                label4: "Visitantes hace una semana",
                label5: "Día más visitado",
                label6: "Día menos visitado",
            });
            if (localStorage.getItem("idGroup") == "0d8c4933-111e-4544-b098-82f3f6001e3c") {
                var nCountInterno = 0;
                var camerasTotal = {};
                this.state["groups"].forEach(element => {
                    API.get(
                        "https://dot",
                        "/analytics/resume-traffic/" +
                            "?groupId=" +
                            element["key"] +
                            "&start=" +
                            formatstartDateTrafic +
                            "&end=" +
                            finishDateFormat
                    )
                        .then(response => {
                            if (response.ok) {
                                response.json().then(data => {
                                    if (data.length !== 0) {
                                        document.getElementById("chartBar").style.display = "";
                                        document.getElementById("loadingBarChartIn").style.display = "none";
                                        document.getElementById("loadingBarChartHistoric").style.display = "none";
                                        console.log("date real", data);
                                        data.forEach(element => {
                                            if (element.come_out !== undefined) {
                                                var date_format = new Date(element.fecha);

                                                let formatted_date =
                                                    date_format.getFullYear() +
                                                    "-" +
                                                    (date_format.getMonth() + 1) +
                                                    "-" +
                                                    date_format.getDate();

                                                if (formatted_date in camerasTotal) {
                                                    camerasTotal[formatted_date] =
                                                        element.come_out + camerasTotal[formatted_date];
                                                } else {
                                                    camerasTotal[formatted_date] = element.come_out;
                                                }
                                            }
                                        });

                                        nCountInterno += 1;
                                        if (nCountInterno == this.state["groups"].length - 1) {
                                            var nCount = 0;

                                            for (var key in camerasTotal) {
                                                arrayX2DateChartBar.push(key);
                                                arrayY2InChartBar.push(camerasTotal[key]);
                                                //arrayY2InChartBar.push({
                                                //  x: nCount,
                                                //  y: camerasTotal[key],
                                                //});

                                                arrayXDateCapacity.push(key);

                                                NumberPeopleOnWeek = NumberPeopleOnWeek + camerasTotal[key];
                                                if (nCount < 7) {
                                                    NumberPeopleOnCompleteWeek = NumberPeopleOnCompleteWeek + camerasTotal[key];
                                                }

                                                nCount += 1;
                                            }

                                            var maximo = 0;
                                            var menor = 5000000000;
                                            for (let i = 0; i < arrayXDateCapacity.length; i++) {
                                                var fechaEjeX = new Date(arrayXDateCapacity[i]);
                                                fechaEjeX.setSeconds(20000);
                                                //var fechaFinal = `${fechaEjeX.getFullYear()}-${fechaEjeX.getMonth()+1}-${fechaEjeX.getDate()}`;
                                                var fechaFinal = `${fechaEjeX.getDate()}-${
                                                    fechaEjeX.getMonth() + 1
                                                }-${fechaEjeX.getFullYear()}`;
                                                arrayDates.push(fechaFinal);

                                                /* Día más visitado */
                                                if (arrayY2InChartBar[i] > maximo) {
                                                    maximo = arrayY2InChartBar[i];
                                                    dateMax = fechaFinal;
                                                }

                                                /* día menos visitado */
                                                if (arrayY2InChartBar[i] <= menor) {
                                                    menor = arrayY2InChartBar[i];
                                                    dateMin = fechaFinal;
                                                }
                                            }

                                            this.setState({ valueLabel5: dateMax, valueLabel6: dateMin });
                                            let firstElement = arrayY2InChartBar[arrayY2InChartBar.length - 7];

                                            if (arrayY2InChartBar.length <= 7) {
                                                this.setState({
                                                    valueLabel1: NumberPeopleOnWeek,
                                                    valueLabel4: 0,
                                                    valueLabel3: NumberPeopleOnCompleteWeek,
                                                });
                                            } else {
                                                this.setState({
                                                    valueLabel1: NumberPeopleOnWeek,
                                                    valueLabel4: firstElement,
                                                    valueLabel3: NumberPeopleOnCompleteWeek,
                                                });
                                            }

                                            // Method to create the classification by day, need to improve.

                                            var nCountCheckGeneral = 0;

                                            for (var j = 0; j < 5; j++) {
                                                var arrayY2InChartBarWeek = [];
                                                for (var i = 0; i < 7; i++) {
                                                    const d = new Date(arrayXDateCapacity[nCountCheckGeneral]);
                                                    let day = d.getDay();

                                                    if (day <= i) {
                                                        arrayY2InChartBarWeek.push(arrayY2InChartBar[nCountCheckGeneral]);
                                                        nCountCheckGeneral += 1;
                                                    } else {
                                                        arrayY2InChartBarWeek.push(0);
                                                    }
                                                }

                                                arrayWeeksMonth.push(arrayY2InChartBarWeek);
                                            }

                                            const weekday = [
                                                "Domingo",
                                                "Lunes",
                                                "Martes",
                                                "Miercoles",
                                                "Jueves",
                                                "Viernes",
                                                "Sabado",
                                            ];

                                            configStateBar = {
                                                type: "bar",
                                                options: {
                                                    plugins: {
                                                        datalabels: {
                                                            display: true,
                                                            color: "white",
                                                            align: "center",
                                                            anchor: "center",
                                                            offset: 6,
                                                            rotation: 270,
                                                            font: {
                                                                size: "14",
                                                                weight: "bold",
                                                            },
                                                        },
                                                    },
                                                    maintainAspectRatio: false,
                                                    responsive: true,
                                                    scales: {
                                                        yAxes: [
                                                            {
                                                                ticks: {
                                                                    beginAtZero: true,
                                                                    stepSize: 100,
                                                                },
                                                            },
                                                        ],
                                                    },
                                                },
                                                data: {
                                                    labels: arrayDates,
                                                    datasets: [
                                                        {
                                                            label: ["Tráfico"],
                                                            data: arrayY2InChartBar,
                                                            backgroundColor: "#7DAB22",
                                                            borderColor: "#7DAB22",
                                                            borderWidth: 3,
                                                        },
                                                    ],
                                                },
                                            };

                                            configStateBarHistoric = {
                                                type: "bar",
                                                plugins: {
                                                    datalabels: {
                                                        color: "pink",
                                                        labels: {
                                                            value: {},
                                                            title: {
                                                                color: "blue",
                                                            },
                                                        },
                                                    },
                                                },
                                                options: {
                                                    plugins: {
                                                        datalabels: {
                                                            display: true,
                                                            color: "white",
                                                            align: "center",
                                                            anchor: "center",
                                                            offset: 6,
                                                            rotation: 270,
                                                            font: {
                                                                size: "14",
                                                                font: "bold",
                                                            },
                                                        },
                                                    },
                                                    maintainAspectRatio: false,
                                                    responsive: true,
                                                    label: true,
                                                    scales: {
                                                        yAxes: [
                                                            {
                                                                ticks: {
                                                                    beginAtZero: true,
                                                                    stepSize: 100,
                                                                },
                                                            },
                                                        ],
                                                    },
                                                },
                                                data: {
                                                    labels: weekday,
                                                    datasets: [
                                                        {
                                                            label: ["S1"],
                                                            data: arrayWeeksMonth[0],
                                                            backgroundColor: "#7DAB22",
                                                            borderColor: "#7DAB22",
                                                            borderWidth: 3,
                                                            labels: {
                                                                title: "hola",
                                                            },
                                                        },
                                                        {
                                                            label: ["S2"],
                                                            data: arrayWeeksMonth[1],
                                                            backgroundColor: "#1e404a",
                                                            borderColor: "#1e404a",
                                                            borderWidth: 3,
                                                        },
                                                        {
                                                            label: ["S3"],
                                                            data: arrayWeeksMonth[2],
                                                            backgroundColor: "#38761d",
                                                            borderColor: "#38761d",
                                                            borderWidth: 3,
                                                        },
                                                        {
                                                            label: ["S4"],
                                                            data: arrayWeeksMonth[3],
                                                            backgroundColor: "#606060",
                                                            borderColor: "#606060",
                                                            borderWidth: 3,
                                                        },
                                                        {
                                                            label: ["S5"],
                                                            data: arrayWeeksMonth[4],
                                                            backgroundColor: "#ABB6B6",
                                                            borderColor: "#ABB6B6",
                                                            borderWidth: 3,
                                                        },
                                                    ],
                                                },
                                            };

                                            console.log("kokun", { arrayWeeksMonth, weekday, data, arrayY2InChartBar });

                                            if (isUpdate) {
                                                let tmpBar = this.state.barGraphic;
                                                tmpBar.data.labels = configStateBar.data.labels;
                                                tmpBar.data.datasets = configStateBar.data.datasets;
                                                tmpBar.update();

                                                // For the historic
                                                let tmpBarHistoric = this.state.barGraphicHistoric;
                                                tmpBarHistoric.data.labels = configStateBarHistoric.data.labels;
                                                tmpBarHistoric.data.datasets = configStateBarHistoric.data.datasets;
                                                tmpBarHistoric.update();

                                                this.setState({
                                                    barGraphic: tmpBar,
                                                    barGraphicHistoric: tmpBarHistoric,
                                                    loadingStaticInBar: false,
                                                    loadingStaticHistoric: false,
                                                });
                                            } else {
                                                let bar = document.getElementById("chartBar").getContext("2d");
                                                let chartBarIn = new Chart(bar, configStateBar);

                                                let barHistoric = document.getElementById("chartBarHistoric").getContext("2d");
                                                let chartBarHistoric = new Chart(barHistoric, configStateBarHistoric);
                                                this.setState({
                                                    barGraphic: chartBarIn,
                                                    barGraphicHistoric: chartBarHistoric,
                                                    loadingStaticInBar: false,
                                                    loadingStaticHistoric: false,
                                                });
                                            }
                                        }
                                    }
                                });
                            } else {
                                console.log("Error" + response);
                            }
                        })
                        .catch(error => console.log("error ", error));
                });

                let finishDateNow = new Date().getTime();

                // Falta Agregar el 365
                var camerasTotal_365 = {};
                let arrayDataCalendar = [];
                let arrayCalendar = [];
                let nCountInterno365 = 0;
                this.state["groups"].forEach(element => {
                    API.get(
                        "https://dot",
                        "/analytics/resume-traffic/" +
                            "?groupId=" +
                            element["key"] +
                            "&start=" +
                            startDateFormat +
                            "&end=" +
                            finishDateFormat
                    )
                        .then(response => {
                            if (response.ok) {
                                response.json().then(data => {
                                    if (data.length !== 0) {
                                        document.getElementById("chartCalendar").style.display = "none";
                                        document.getElementById("loadingChartCalendar").style.display = "none";
                                        document.getElementById("loadingBarCalendar").style.display = "";
                                        data.forEach(element => {
                                            if (element.come_out !== undefined) {
                                                var date_format = new Date(element.fecha);
                                                let formatted_date =
                                                    date_format.getFullYear() +
                                                    "-" +
                                                    (date_format.getMonth() + 1) +
                                                    "-" +
                                                    date_format.getDate();

                                                if (formatted_date in camerasTotal_365) {
                                                    camerasTotal_365[formatted_date] =
                                                        element.come_out + camerasTotal_365[formatted_date];
                                                } else {
                                                    camerasTotal_365[formatted_date] = element.come_out;
                                                }
                                            }
                                        });
                                        nCountInterno365 += 1;
                                        if (nCountInterno365 == this.state["groups"].length - 1) {
                                            var nCount = 0;
                                            var archivos = Object.keys(camerasTotal_365).length;

                                            this.setState({ datosTotales: archivos });
                                            const archivosTotales = this.state.datosTotales;
                                            var porcentaje = 100 / this.state.datosTotales;
                                            const self = this;
                                            for (var key in camerasTotal_365) {
                                                document.getElementById("loadingBarCalendar").style.display = "";
                                                var conversion = new Date(key);
                                                var valor = camerasTotal_365[key];
                                                if (conversion.getMonth() < 9) {
                                                    if (conversion.getDate() < 9) {
                                                        let completeDay = `${conversion.getFullYear()}-0${
                                                            conversion.getMonth() + 1
                                                        }-0${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    } else {
                                                        let completeDay = `${conversion.getFullYear()}-0${
                                                            conversion.getMonth() + 1
                                                        }-${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    }
                                                } else {
                                                    if (conversion.getDate() < 9) {
                                                        let completeDay = `${conversion.getFullYear()}-${
                                                            conversion.getMonth() + 1
                                                        }-0${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    } else {
                                                        let completeDay = `${conversion.getFullYear()}-${
                                                            conversion.getMonth() + 1
                                                        }-${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    }
                                                }

                                                self.setState({
                                                    progress: self.state.progress + porcentaje,
                                                    datosTotales: self.state.datosTotales - 1,
                                                });

                                                nCount++;
                                            }

                                            document.getElementById("chartCalendar").style.display = "";
                                            document.getElementById("loadingBarCalendar").style.display = "none";
                                            self.setState({ progress: 0.0 });

                                            this.setState({
                                                loadingCalendar: false,
                                                dataCalendar: arrayDataCalendar,
                                            });
                                        }
                                    }
                                });
                            }
                        })
                        .catch(error => console.log("error ", error));
                });
            } else {
                API.get(
                    "https://dot",
                    "/analytics/resume-traffic/" +
                        "?groupId=" +
                        localStorage.getItem("idGroup") +
                        "&start=" +
                        formatstartDateTrafic +
                        "&end=" +
                        finishDateFormat
                )
                    .then(response => {
                        if (response.ok) {
                            response.json().then(data => {
                                if (data.length !== 0) {
                                    document.getElementById("chartBar").style.display = "";
                                    document.getElementById("loadingBarChartIn").style.display = "none";
                                    document.getElementById("loadingBarChartHistoric").style.display = "none";
                                    console.log("aca data", data);
                                    var nCountCamera = 0;
                                    data.forEach(element => {
                                        if (element.come_out !== undefined) {
                                            arrayX2DateChartBar.push(element.fecha);
                                            arrayY2InChartBar.push(element.come_out);

                                            arrayXDateCapacity.push(element.fecha);

                                            if (typeof element.come_out !== "undefined" && element.come_out) {
                                                NumberPeopleOnWeek = NumberPeopleOnWeek + element.come_out;

                                                if (nCountCamera < 7) {
                                                    NumberPeopleOnCompleteWeek = NumberPeopleOnCompleteWeek + element.come_out;
                                                    nCountCamera += 1;
                                                }
                                            }
                                        }
                                    });

                                    var maximo = 0;
                                    var menor = 5000000000;
                                    for (let i = 0; i < arrayXDateCapacity.length; i++) {
                                        var fechaEjeX = new Date(arrayXDateCapacity[i]);
                                        fechaEjeX.setSeconds(20000);
                                        var fechaFinal = `${fechaEjeX.getFullYear()}-${
                                            fechaEjeX.getMonth() + 1
                                        }-${fechaEjeX.getDate()}`;
                                        arrayDates.push(fechaFinal);
                                        /* Día más visitado */
                                        if (arrayY2InChartBar[i] > maximo) {
                                            maximo = arrayY2InChartBar[i];
                                            dateMax = fechaFinal;
                                        }

                                        /* día menos visitado */
                                        if (arrayY2InChartBar[i] <= menor) {
                                            menor = arrayY2InChartBar[i];
                                            dateMin = fechaFinal;
                                        }
                                    }

                                    this.setState({ valueLabel5: dateMax, valueLabel6: dateMin });

                                    let firstElement = arrayY2InChartBar[arrayY2InChartBar.length - 7];

                                    if (arrayY2InChartBar.length <= 7) {
                                        this.setState({
                                            valueLabel1: NumberPeopleOnWeek,
                                            valueLabel4: 0,
                                            valueLabel3: NumberPeopleOnCompleteWeek,
                                        });
                                    } else {
                                        this.setState({
                                            valueLabel1: NumberPeopleOnWeek,
                                            valueLabel4: firstElement,
                                            valueLabel3: NumberPeopleOnCompleteWeek,
                                        });
                                    }
                                } else {
                                    document.getElementById("chartBar").style.display = "none";
                                    document.getElementById("loadingBarChartIn").style.display = "";
                                    document.getElementById("loadingBarChartHistoric").style.display = "";
                                }

                                configStateBar = {
                                    type: "bar",
                                    options: {
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        scales: {
                                            yAxes: [
                                                {
                                                    ticks: {
                                                        beginAtZero: true,
                                                        stepSize: 100,
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                    data: {
                                        labels: arrayDates,
                                        datasets: [
                                            {
                                                label: ["Tráfico"],
                                                data: arrayY2InChartBar,
                                                backgroundColor: "#7DAB22",
                                                borderColor: "#7DAB22",
                                                borderWidth: 3,
                                            },
                                        ],
                                    },
                                };

                                if (isUpdate) {
                                    let tmpBar = this.state.barGraphic;
                                    tmpBar.data.labels = configStateBar.data.labels;
                                    tmpBar.data.datasets = configStateBar.data.datasets;
                                    tmpBar.update();
                                    this.setState({
                                        barGraphic: tmpBar,
                                        loadingStaticInBar: false,
                                        loadingStaticHistoric: false,
                                    });
                                } else {
                                    let bar = document.getElementById("chartBar").getContext("2d");
                                    let chartBarIn = new Chart(bar, configStateBar);

                                    this.setState({
                                        barGraphic: chartBarIn,
                                        loadingStaticInBar: false,
                                        loadingStaticHistoric: false,
                                    });
                                }
                            });
                        } else {
                            console.log("Error" + response);
                        }
                    })
                    .catch(error => console.log("error ", error));
                let finishDateNow = new Date().getTime();

                let arrayCalendar = [];
                let arrayDataCalendar = [];

                API.get(
                    "https://dot",
                    "/analytics/resume-traffic/" +
                        "?groupId=" +
                        localStorage.getItem("idGroup") +
                        "&start=" +
                        startDateFormat +
                        "&end=" +
                        finishDateFormat
                )
                    .then(response => {
                        if (response.ok) {
                            response.json().then(data => {
                                if (data.length !== 0) {
                                    document.getElementById("chartCalendar").style.display = "none";
                                    document.getElementById("loadingChartCalendar").style.display = "none";
                                    document.getElementById("loadingBarCalendar").style.display = "";

                                    this.setState({ datosTotales: data.length });
                                    const archivosTotales = this.state.datosTotales;
                                    var porcentaje = 100 / this.state.datosTotales;
                                    data = data;
                                    const self = this;

                                    for (let i = 0; i < archivosTotales; i++) {
                                        document.getElementById("loadingBarCalendar").style.display = "";
                                        setTimeout(function () {
                                            data.forEach(element => {
                                                if (element.come_out !== undefined) {
                                                    arrayCalendar.push({
                                                        day: element.fecha,
                                                        value: element.come_out,
                                                    });
                                                }
                                            });

                                            var conversion = new Date(arrayCalendar[i].day);
                                            var valor = arrayCalendar[i].value;

                                            if (conversion.getMonth() < 9) {
                                                if (conversion.getDate() < 9) {
                                                    let completeDay = `${conversion.getFullYear()}-0${
                                                        conversion.getMonth() + 1
                                                    }-0${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                } else {
                                                    let completeDay = `${conversion.getFullYear()}-0${
                                                        conversion.getMonth() + 1
                                                    }-${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                }
                                            } else {
                                                if (conversion.getDate() < 9) {
                                                    let completeDay = `${conversion.getFullYear()}-${
                                                        conversion.getMonth() + 1
                                                    }-0${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                } else {
                                                    let completeDay = `${conversion.getFullYear()}-${
                                                        conversion.getMonth() + 1
                                                    }-${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                }
                                            }

                                            self.setState({
                                                progress: self.state.progress + porcentaje,
                                                datosTotales: self.state.datosTotales - 1,
                                            });

                                            if (i == archivosTotales - 1) {
                                                document.getElementById("chartCalendar").style.display = "";
                                                document.getElementById("loadingBarCalendar").style.display = "none";
                                                self.setState({ progress: 0.0 });
                                            }
                                        }, i);
                                    }

                                    this.setState({
                                        //loadingCalendar: false,
                                        dataCalendar: arrayDataCalendar,
                                    });
                                } else {
                                    document.getElementById("chartCalendar").style.display = "none";
                                    document.getElementById("loadingChartCalendar").style.display = "";
                                    document.getElementById("loadingBarCalendar").style.display = "none";

                                    this.setState({
                                        loadingCalendar: false,
                                    });
                                }
                            });
                        }
                    })
                    .catch(error => console.log("error ", error));
            }
        } else if (localStorage.getItem("idAlgorithm") == "characterization") {
            this.setState({
                label1: "Total de visitantes hombres que han ingresado durante este mes",
                label2: "Total de visitantes mujeres que han ingresado durante este mes",
                label3: "Día más visitado hombres",
                label4: "Día menos visitado hombres",
                label5: "Día más visitado mujeres",
                label6: "Día menos visitado mujeres",
            });
            if (localStorage.getItem("idGroup") == "0d8c4933-111e-4544-b098-82f3f6001e3c") {
                var nCountInterno = 0;
                var camerasTotalMen = {};
                var camerasTotalWomen = {};

                this.state["groups"].forEach(element => {
                    API.get(
                        "https://dot",
                        "/analytics/resume-traffic/" +
                            "?groupId=" +
                            element["key"] +
                            "&start=" +
                            formatstartDateTrafic +
                            "&end=" +
                            finishDateFormat
                    )
                        .then(response => {
                            if (response.ok) {
                                response.json().then(data => {
                                    if (data.length !== 0) {
                                        document.getElementById("chartBar").style.display = "";
                                        document.getElementById("loadingBarChartIn").style.display = "none";
                                        document.getElementById("loadingBarChartHistoric").style.display = "none";

                                        data.forEach(element => {
                                            if (element.nMen !== undefined) {
                                                var date_format = new Date(element.fecha);
                                                let formatted_date =
                                                    date_format.getFullYear() +
                                                    "-" +
                                                    (date_format.getMonth() + 1) +
                                                    "-" +
                                                    date_format.getDate();

                                                if (formatted_date in camerasTotalMen) {
                                                    camerasTotalMen[formatted_date] =
                                                        element.nMen + camerasTotalMen[formatted_date];
                                                    camerasTotalWomen[formatted_date] =
                                                        element.nWomen + camerasTotalWomen[formatted_date];
                                                } else {
                                                    camerasTotalMen[formatted_date] = element.nMen;
                                                    camerasTotalWomen[formatted_date] = element.nWomen;
                                                }
                                            }
                                        });

                                        NumberPeopleOnWeek = 0;
                                        nCountInterno += 1;
                                        if (nCountInterno == this.state["groups"].length - 1) {
                                            var nCount = 0;
                                            for (var key in camerasTotalMen) {
                                                if (isNaN(camerasTotalMen[key]) === false) {
                                                    arrayX2DateChartBar.push(key);
                                                    arrayY2InChartBarMen.push(camerasTotalMen[key]);
                                                    arrayY2InChartBarWomen.push(camerasTotalWomen[key]);

                                                    arrayXDateCapacity.push(key);
                                                    NumberPeopleOnWeek = NumberPeopleOnWeek + camerasTotalMen[key];
                                                    NumberPeopleOnWeekWoman = NumberPeopleOnWeekWoman + camerasTotalWomen[key];
                                                    nCount += 1;
                                                }
                                            }

                                            var maximo = 0;
                                            var menor = 5000000000;
                                            var maximoWoman = 0;
                                            var menorWoman = 5000000000;

                                            for (let i = 0; i < arrayXDateCapacity.length; i++) {
                                                var fechaEjeX = new Date(arrayXDateCapacity[i]);
                                                fechaEjeX.setSeconds(20000);
                                                //var fechaFinal = `${fechaEjeX.getFullYear()}-${fechaEjeX.getMonth()+1}-${fechaEjeX.getDate()}`;
                                                var fechaFinal = `${fechaEjeX.getDate()}-${
                                                    fechaEjeX.getMonth() + 1
                                                }-${fechaEjeX.getFullYear()}`;
                                                arrayDates.push(fechaFinal);

                                                /* Día más visitado */
                                                if (arrayY2InChartBarMen[i] > maximo) {
                                                    maximo = arrayY2InChartBarMen[i];
                                                    dateMax = fechaFinal;
                                                }

                                                /* día menos visitado Hombres*/
                                                if (arrayY2InChartBarMen[i] <= menor) {
                                                    menor = arrayY2InChartBarMen[i];
                                                    dateMin = fechaFinal;
                                                }

                                                /* Día más visitado */
                                                if (arrayY2InChartBarWomen[i] > maximoWoman) {
                                                    maximoWoman = arrayY2InChartBarWomen[i];
                                                    dateMaxWoman = fechaFinal;
                                                }

                                                /* día menos visitado Hombres*/
                                                if (arrayY2InChartBarWomen[i] <= menorWoman) {
                                                    menorWoman = arrayY2InChartBarWomen[i];
                                                    dateMinWoman = fechaFinal;
                                                }
                                            }

                                            //this.setState({ valueLabel5: dateMax , valueLabel6: dateMin, valueLabel3: dateMaxWoman , valueLabel4: dateMinWoman});
                                            this.setState({
                                                valueLabel5: dateMaxWoman,
                                                valueLabel6: dateMinWoman,
                                                valueLabel3: dateMax,
                                                valueLabel4: dateMin,
                                            });
                                            this.setState({
                                                valueLabel1: NumberPeopleOnWeek,
                                                valueLabel2: NumberPeopleOnWeekWoman,
                                            });

                                            this.setState({
                                                characterizationData: {
                                                    data: getCharacterizationData({
                                                        arrayY2InChartBarMen,
                                                        arrayY2InChartBarWomen,
                                                        arrayDates,
                                                    }),
                                                    settings: {
                                                        barName: "Ingresos",
                                                        dataKey: "value",
                                                        xKey: "date",
                                                        keys: [
                                                            { key: "men", label: "Hombres" },
                                                            { key: "women", label: "Mujeres" },
                                                        ],
                                                    },
                                                },
                                            });

                                            configStateBar = {
                                                type: "bar",
                                                options: {
                                                    maintainAspectRatio: false,
                                                    responsive: true,
                                                    scales: {
                                                        yAxes: [
                                                            {
                                                                ticks: {
                                                                    beginAtZero: true,
                                                                    stepSize: 100,
                                                                },
                                                            },
                                                        ],
                                                    },
                                                },
                                                data: {
                                                    labels: arrayDates,
                                                    datasets: [
                                                        {
                                                            label: ["% Hombres"],
                                                            data: arrayY2InChartBarMen,
                                                            backgroundColor: "#7DAB22",
                                                            borderColor: "#7DAB22",
                                                            borderWidth: 3,
                                                        },
                                                        {
                                                            label: ["% Mujeres"],
                                                            data: arrayY2InChartBarWomen,
                                                            backgroundColor: "#606060",
                                                            borderColor: "#606060",
                                                            borderWidth: 3,
                                                        },
                                                    ],
                                                },
                                            };

                                            if (isUpdate) {
                                                let tmpBar = this.state.barGraphic;
                                                tmpBar.data.labels = configStateBar.data.labels;
                                                tmpBar.data.datasets = configStateBar.data.datasets;
                                                tmpBar.update();
                                                this.setState({
                                                    barGraphic: tmpBar,
                                                    loadingStaticInBar: false,
                                                    loadingStaticHistoric: false,
                                                });
                                            } else {
                                                let bar = document.getElementById("chartBar").getContext("2d");
                                                let chartBarIn = new Chart(bar, configStateBar);
                                                this.setState({
                                                    barGraphic: chartBarIn,
                                                    loadingStaticInBar: false,
                                                    loadingStaticHistoric: false,
                                                });
                                            }
                                        }
                                    }
                                });
                            } else {
                                console.log("Error" + response);
                            }
                        })
                        .catch(error => console.log("error ", error));
                });

                // Falta Agregar el 365
                var camerasTotal_365 = {};
                let arrayDataCalendar = [];
                let arrayCalendar = [];
                let nCountInterno365 = 0;
                this.state["groups"].forEach(element => {
                    API.get(
                        "https://dot",
                        "/analytics/resume-traffic/" +
                            "?groupId=" +
                            element["key"] +
                            "&start=" +
                            startDateFormat +
                            "&end=" +
                            finishDateFormat
                    )
                        .then(response => {
                            if (response.ok) {
                                response.json().then(data => {
                                    if (data.length !== 0) {
                                        document.getElementById("chartCalendar").style.display = "none";
                                        document.getElementById("loadingChartCalendar").style.display = "none";
                                        document.getElementById("loadingBarCalendar").style.display = "";
                                        data.forEach(element => {
                                            if (element.come_out !== undefined) {
                                                var date_format = new Date(element.fecha);
                                                let formatted_date =
                                                    date_format.getFullYear() +
                                                    "-" +
                                                    (date_format.getMonth() + 1) +
                                                    "-" +
                                                    date_format.getDate();

                                                if (formatted_date in camerasTotal_365) {
                                                    camerasTotal_365[formatted_date] =
                                                        element.come_out + camerasTotal_365[formatted_date];
                                                } else {
                                                    camerasTotal_365[formatted_date] = element.come_out;
                                                }
                                            }
                                        });
                                        nCountInterno365 += 1;
                                        if (nCountInterno365 == this.state["groups"].length - 1) {
                                            var nCount = 0;
                                            var archivos = Object.keys(camerasTotal_365).length;

                                            this.setState({ datosTotales: archivos });
                                            const archivosTotales = this.state.datosTotales;
                                            var porcentaje = 100 / this.state.datosTotales;
                                            const self = this;
                                            for (var key in camerasTotal_365) {
                                                document.getElementById("loadingBarCalendar").style.display = "";
                                                var conversion = new Date(key);
                                                var valor = camerasTotal_365[key];
                                                if (conversion.getMonth() < 9) {
                                                    if (conversion.getDate() < 9) {
                                                        let completeDay = `${conversion.getFullYear()}-0${
                                                            conversion.getMonth() + 1
                                                        }-0${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    } else {
                                                        let completeDay = `${conversion.getFullYear()}-0${
                                                            conversion.getMonth() + 1
                                                        }-${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    }
                                                } else {
                                                    if (conversion.getDate() < 9) {
                                                        let completeDay = `${conversion.getFullYear()}-${
                                                            conversion.getMonth() + 1
                                                        }-0${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    } else {
                                                        let completeDay = `${conversion.getFullYear()}-${
                                                            conversion.getMonth() + 1
                                                        }-${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    }
                                                }

                                                self.setState({
                                                    progress: self.state.progress + porcentaje,
                                                    datosTotales: self.state.datosTotales - 1,
                                                });

                                                nCount++;
                                            }

                                            document.getElementById("chartCalendar").style.display = "";
                                            document.getElementById("loadingBarCalendar").style.display = "none";
                                            self.setState({ progress: 0.0 });

                                            this.setState({
                                                loadingCalendar: false,
                                                dataCalendar: arrayDataCalendar,
                                            });
                                        }
                                    }
                                });
                            }
                        })
                        .catch(error => console.log("error ", error));
                });
            } else {
                API.get(
                    "https://dot",
                    "/analytics/resume-traffic/" +
                        "?groupId=" +
                        localStorage.getItem("idGroup") +
                        "&start=" +
                        formatstartDateTrafic +
                        "&end=" +
                        finishDateFormat
                )
                    .then(response => {
                        if (response.ok) {
                            response.json().then(data => {
                                if (data.length !== 0) {
                                    document.getElementById("chartBar").style.display = "";
                                    document.getElementById("loadingBarChartIn").style.display = "none";
                                    document.getElementById("loadingBarChartHistoric").style.display = "none";

                                    data.forEach(element => {
                                        if (element.nWomen !== undefined) {
                                            arrayX2DateChartBar.push(element.fecha);
                                            arrayY2InChartBarMen.push(element.nMen);
                                            arrayY2InChartBarWomen.push(element.nWomen);

                                            arrayXDateCapacity.push(element.fecha);

                                            if (typeof element.nWomen !== "undefined" && element.nWomen) {
                                                NumberPeopleOnWeek = NumberPeopleOnWeek + element.nMen;
                                                NumberPeopleOnWeekWoman = NumberPeopleOnWeekWoman + element.nWomen;
                                            }
                                        }
                                    });

                                    var maximo = 0;
                                    var menor = 5000000000;
                                    var maximoWoman = 0;
                                    var menorWoman = 5000000000;
                                    for (let i = 0; i < arrayXDateCapacity.length; i++) {
                                        var fechaEjeX = new Date(arrayXDateCapacity[i]);
                                        fechaEjeX.setSeconds(20000);
                                        var fechaFinal = `${fechaEjeX.getFullYear()}-${
                                            fechaEjeX.getMonth() + 1
                                        }-${fechaEjeX.getDate()}`;
                                        arrayDates.push(fechaFinal);

                                        /* Día más visitado */
                                        if (arrayY2InChartBarMen[i] > maximo) {
                                            maximo = arrayY2InChartBarMen[i];
                                            dateMax = fechaFinal;
                                        }

                                        /* día menos visitado Hombres*/
                                        if (arrayY2InChartBarMen[i] <= menor) {
                                            menor = arrayY2InChartBarMen[i];
                                            dateMin = fechaFinal;
                                        }

                                        /* Día más visitado */
                                        if (arrayY2InChartBarWomen[i] > maximoWoman) {
                                            maximoWoman = arrayY2InChartBarWomen[i];
                                            dateMaxWoman = fechaFinal;
                                        }

                                        /* día menos visitado Hombres*/
                                        if (arrayY2InChartBarWomen[i] <= menorWoman) {
                                            menorWoman = arrayY2InChartBarWomen[i];
                                            dateMinWoman = fechaFinal;
                                        }
                                    }

                                    this.setState({
                                        valueLabel5: dateMax,
                                        valueLabel6: dateMin,
                                        valueLabel3: dateMaxWoman,
                                        valueLabel4: dateMinWoman,
                                    });
                                    this.setState({ valueLabel1: NumberPeopleOnWeek, valueLabel2: NumberPeopleOnWeekWoman });
                                } else {
                                    document.getElementById("chartBar").style.display = "none";
                                    document.getElementById("loadingBarChartIn").style.display = "";
                                    document.getElementById("loadingBarChartHistoric").style.display = "";
                                }

                                configStateBar = {
                                    type: "bar",
                                    options: {
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        scales: {
                                            yAxes: [
                                                {
                                                    ticks: {
                                                        beginAtZero: true,
                                                        stepSize: 100,
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                    data: {
                                        labels: arrayDates,
                                        datasets: [
                                            {
                                                label: ["Hombres"],
                                                data: arrayY2InChartBarMen,
                                                backgroundColor: "#7DAB22",
                                                borderColor: "#7DAB22",
                                                borderWidth: 3,
                                            },
                                            {
                                                label: ["Mujeres"],
                                                data: arrayY2InChartBarWomen,
                                                backgroundColor: "#606060",
                                                borderColor: "#606060",
                                                borderWidth: 3,
                                            },
                                        ],
                                    },
                                };

                                if (isUpdate) {
                                    let tmpBar = this.state.barGraphic;
                                    tmpBar.data.labels = configStateBar.data.labels;
                                    tmpBar.data.datasets = configStateBar.data.datasets;
                                    tmpBar.update();
                                    this.setState({
                                        barGraphic: tmpBar,
                                        loadingStaticInBar: false,
                                        loadingStaticHistoric: false,
                                    });
                                } else {
                                    let bar = document.getElementById("chartBar").getContext("2d");
                                    let chartBarIn = new Chart(bar, configStateBar);

                                    this.setState({
                                        barGraphic: chartBarIn,
                                        loadingStaticInBar: false,
                                        loadingStaticHistoric: false,
                                    });
                                }
                            });
                        } else {
                            console.log("Error" + response);
                        }
                    })
                    .catch(error => console.log("error ", error));
                let finishDateNow = new Date().getTime();

                let arrayCalendar = [];
                let arrayDataCalendar = [];

                API.get(
                    "https://dot",
                    "/analytics/resume-traffic/" +
                        "?groupId=" +
                        localStorage.getItem("idGroup") +
                        "&start=" +
                        startDateFormat +
                        "&end=" +
                        finishDateFormat
                )
                    .then(response => {
                        if (response.ok) {
                            response.json().then(data => {
                                if (data.length !== 0) {
                                    document.getElementById("chartCalendar").style.display = "none";
                                    document.getElementById("loadingChartCalendar").style.display = "none";
                                    document.getElementById("loadingBarCalendar").style.display = "";

                                    this.setState({ datosTotales: data.length });
                                    const archivosTotales = this.state.datosTotales;
                                    var porcentaje = 100 / this.state.datosTotales;
                                    data = data;
                                    const self = this;

                                    for (let i = 0; i < archivosTotales; i++) {
                                        document.getElementById("loadingBarCalendar").style.display = "";
                                        setTimeout(function () {
                                            data.forEach(element => {
                                                if (element.come_out !== undefined) {
                                                    arrayCalendar.push({
                                                        day: element.fecha,
                                                        value: element.come_out,
                                                    });
                                                }
                                            });

                                            var conversion = new Date(arrayCalendar[i].day);
                                            var valor = arrayCalendar[i].value;

                                            if (conversion.getMonth() < 9) {
                                                if (conversion.getDate() < 9) {
                                                    let completeDay = `${conversion.getFullYear()}-0${
                                                        conversion.getMonth() + 1
                                                    }-0${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                } else {
                                                    let completeDay = `${conversion.getFullYear()}-0${
                                                        conversion.getMonth() + 1
                                                    }-${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                }
                                            } else {
                                                if (conversion.getDate() < 9) {
                                                    let completeDay = `${conversion.getFullYear()}-${
                                                        conversion.getMonth() + 1
                                                    }-0${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                } else {
                                                    let completeDay = `${conversion.getFullYear()}-${
                                                        conversion.getMonth() + 1
                                                    }-${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                }
                                            }

                                            self.setState({
                                                progress: self.state.progress + porcentaje,
                                                datosTotales: self.state.datosTotales - 1,
                                            });

                                            if (i == archivosTotales - 1) {
                                                document.getElementById("chartCalendar").style.display = "";
                                                document.getElementById("loadingBarCalendar").style.display = "none";
                                                self.setState({ progress: 0.0 });
                                            }
                                        }, i);
                                    }

                                    this.setState({
                                        //loadingCalendar: false,
                                        dataCalendar: arrayDataCalendar,
                                    });
                                } else {
                                    document.getElementById("chartCalendar").style.display = "none";
                                    document.getElementById("loadingChartCalendar").style.display = "";
                                    document.getElementById("loadingBarCalendar").style.display = "none";

                                    this.setState({
                                        loadingCalendar: false,
                                    });
                                }
                            });
                        }
                    })
                    .catch(error => console.log("error ", error));
            }
        } else {
            this.setState({
                label1: "Total de mascotas que han ingresado durante este mes",
                label2: "Total de mascotas de hace una semana",
                label3: "Cantidad de mascotas día con más ingreso",
                label4: "Cantidad de mascotas día con menos ingreso",
                label5: "Día más visitado mascotas",
                label6: "Día menos visitado mascotas",
            });

            if (localStorage.getItem("idGroup") == "0d8c4933-111e-4544-b098-82f3f6001e3c") {
                var nCountInterno = 0;
                var camerasTotalPets = {};

                this.state["groups"].forEach(element => {
                    API.get(
                        "https://dot",
                        "/analytics/resume-traffic/" +
                            "?groupId=" +
                            element["key"] +
                            "&start=" +
                            formatstartDateTrafic +
                            "&end=" +
                            finishDateFormat
                    )
                        .then(response => {
                            if (response.ok) {
                                response.json().then(data => {
                                    if (data.length !== 0) {
                                        document.getElementById("chartBar").style.display = "";
                                        document.getElementById("loadingBarChartIn").style.display = "none";
                                        document.getElementById("loadingBarChartHistoric").style.display = "none";

                                        data.forEach(element => {
                                            if (element.nPets !== undefined) {
                                                var date_format = new Date(element.fecha);
                                                let formatted_date =
                                                    date_format.getFullYear() +
                                                    "-" +
                                                    (date_format.getMonth() + 1) +
                                                    "-" +
                                                    date_format.getDate();

                                                if (formatted_date in camerasTotalPets) {
                                                    camerasTotalPets[formatted_date] =
                                                        element.nPets + camerasTotalPets[formatted_date];
                                                } else {
                                                    camerasTotalPets[formatted_date] = element.nPets;
                                                }
                                            }
                                        });

                                        NumberPeopleOnWeek = 0;
                                        nCountInterno += 1;
                                        if (nCountInterno == this.state["groups"].length - 1) {
                                            var nCount = 0;

                                            for (var key in camerasTotalPets) {
                                                if (isNaN(camerasTotalPets[key]) === false) {
                                                    arrayX2DateChartBar.push(key);
                                                    arrayY2InChartBarPets.push(camerasTotalPets[key]);

                                                    arrayXDateCapacity.push(key);
                                                    NumberPeopleOnWeek = NumberPeopleOnWeek + camerasTotalPets[key];
                                                    nCount += 1;
                                                }
                                            }

                                            var maximo = 0;
                                            var menor = 5000000000;
                                            for (let i = 0; i < arrayXDateCapacity.length; i++) {
                                                var fechaEjeX = new Date(arrayXDateCapacity[i]);
                                                fechaEjeX.setSeconds(20000);
                                                //var fechaFinal = `${fechaEjeX.getFullYear()}-${fechaEjeX.getMonth()+1}-${fechaEjeX.getDate()}`;
                                                var fechaFinal = `${fechaEjeX.getDate()}-${
                                                    fechaEjeX.getMonth() + 1
                                                }-${fechaEjeX.getFullYear()}`;
                                                arrayDates.push(fechaFinal);

                                                /* Día más visitado */
                                                if (arrayY2InChartBarPets[i] > maximo) {
                                                    maximo = arrayY2InChartBarPets[i];
                                                    dateMax = fechaFinal;
                                                }

                                                /* día menos visitado Hombres*/
                                                if (arrayY2InChartBarPets[i] <= menor) {
                                                    menor = arrayY2InChartBarPets[i];
                                                    dateMin = fechaFinal;
                                                }
                                            }

                                            let firstElement = arrayY2InChartBarPets[arrayY2InChartBarPets.length - 7];

                                            //this.setState({ valueLabel5: dateMax , valueLabel6: dateMin, valueLabel3: dateMaxWoman , valueLabel4: dateMinWoman});
                                            this.setState({
                                                valueLabel3: maximo,
                                                valueLabel4: menor,
                                                valueLabel5: dateMax,
                                                valueLabel6: dateMin,
                                            });
                                            this.setState({ valueLabel1: NumberPeopleOnWeek, valueLabel2: firstElement });

                                            configStateBar = {
                                                type: "bar",
                                                options: {
                                                    maintainAspectRatio: false,
                                                    responsive: true,
                                                    scales: {
                                                        yAxes: [
                                                            {
                                                                ticks: {
                                                                    beginAtZero: true,
                                                                    stepSize: 100,
                                                                },
                                                            },
                                                        ],
                                                    },
                                                },
                                                data: {
                                                    labels: arrayDates,
                                                    datasets: [
                                                        {
                                                            label: ["Mascotas"],
                                                            data: arrayY2InChartBarPets,
                                                            backgroundColor: "#7DAB22",
                                                            borderColor: "#7DAB22",
                                                            borderWidth: 3,
                                                        },
                                                    ],
                                                },
                                            };

                                            if (isUpdate) {
                                                let tmpBar = this.state.barGraphic;
                                                tmpBar.data.labels = configStateBar.data.labels;
                                                tmpBar.data.datasets = configStateBar.data.datasets;
                                                tmpBar.update();
                                                this.setState({
                                                    barGraphic: tmpBar,
                                                    loadingStaticInBar: false,
                                                    loadingStaticHistoric: false,
                                                });
                                            } else {
                                                let bar = document.getElementById("chartBar").getContext("2d");
                                                let chartBarIn = new Chart(bar, configStateBar);

                                                this.setState({
                                                    barGraphic: chartBarIn,
                                                    loadingStaticInBar: false,
                                                    loadingStaticHistoric: false,
                                                });
                                            }
                                        }
                                    }
                                });
                            } else {
                                console.log("Error" + response);
                            }
                        })
                        .catch(error => console.log("error ", error));
                });

                // Falta Agregar el 365
                var camerasTotal_365 = {};
                let arrayDataCalendar = [];
                let arrayCalendar = [];
                let nCountInterno365 = 0;
                this.state["groups"].forEach(element => {
                    API.get(
                        "https://dot",
                        "/analytics/resume-traffic/" +
                            "?groupId=" +
                            element["key"] +
                            "&start=" +
                            startDateFormat +
                            "&end=" +
                            finishDateFormat
                    )
                        .then(response => {
                            if (response.ok) {
                                response.json().then(data => {
                                    if (data.length !== 0) {
                                        document.getElementById("chartCalendar").style.display = "none";
                                        document.getElementById("loadingChartCalendar").style.display = "none";
                                        document.getElementById("loadingBarCalendar").style.display = "";
                                        data.forEach(element => {
                                            if (element.come_out !== undefined) {
                                                var date_format = new Date(element.fecha);
                                                let formatted_date =
                                                    date_format.getFullYear() +
                                                    "-" +
                                                    (date_format.getMonth() + 1) +
                                                    "-" +
                                                    date_format.getDate();

                                                if (formatted_date in camerasTotal_365) {
                                                    camerasTotal_365[formatted_date] =
                                                        element.come_out + camerasTotal_365[formatted_date];
                                                } else {
                                                    camerasTotal_365[formatted_date] = element.come_out;
                                                }
                                            }
                                        });
                                        nCountInterno365 += 1;
                                        if (nCountInterno365 == this.state["groups"].length - 1) {
                                            var nCount = 0;
                                            var archivos = Object.keys(camerasTotal_365).length;

                                            this.setState({ datosTotales: archivos });
                                            const archivosTotales = this.state.datosTotales;
                                            var porcentaje = 100 / this.state.datosTotales;
                                            const self = this;
                                            for (var key in camerasTotal_365) {
                                                document.getElementById("loadingBarCalendar").style.display = "";
                                                var conversion = new Date(key);
                                                var valor = camerasTotal_365[key];
                                                if (conversion.getMonth() < 9) {
                                                    if (conversion.getDate() < 9) {
                                                        let completeDay = `${conversion.getFullYear()}-0${
                                                            conversion.getMonth() + 1
                                                        }-0${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    } else {
                                                        let completeDay = `${conversion.getFullYear()}-0${
                                                            conversion.getMonth() + 1
                                                        }-${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    }
                                                } else {
                                                    if (conversion.getDate() < 9) {
                                                        let completeDay = `${conversion.getFullYear()}-${
                                                            conversion.getMonth() + 1
                                                        }-0${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    } else {
                                                        let completeDay = `${conversion.getFullYear()}-${
                                                            conversion.getMonth() + 1
                                                        }-${conversion.getDate()}`;
                                                        arrayDataCalendar.push({
                                                            day: completeDay,
                                                            value: valor,
                                                        });
                                                    }
                                                }

                                                self.setState({
                                                    progress: self.state.progress + porcentaje,
                                                    datosTotales: self.state.datosTotales - 1,
                                                });

                                                nCount++;
                                            }

                                            document.getElementById("chartCalendar").style.display = "";
                                            document.getElementById("loadingBarCalendar").style.display = "none";
                                            self.setState({ progress: 0.0 });

                                            this.setState({
                                                loadingCalendar: false,
                                                dataCalendar: arrayDataCalendar,
                                            });
                                        }
                                    }
                                });
                            }
                        })
                        .catch(error => console.log("error ", error));
                });
            } else {
                API.get(
                    "https://dot",
                    "/analytics/resume-traffic/" +
                        "?groupId=" +
                        localStorage.getItem("idGroup") +
                        "&start=" +
                        formatstartDateTrafic +
                        "&end=" +
                        finishDateFormat
                )
                    .then(response => {
                        if (response.ok) {
                            response.json().then(data => {
                                if (data.length !== 0) {
                                    document.getElementById("chartBar").style.display = "";
                                    document.getElementById("loadingBarChartIn").style.display = "none";
                                    document.getElementById("loadingBarChartHistoric").style.display = "none";

                                    data.forEach(element => {
                                        if (element.nWomen !== undefined) {
                                            arrayX2DateChartBar.push(element.fecha);
                                            arrayY2InChartBarPets.push(element.nPets);

                                            arrayXDateCapacity.push(element.fecha);

                                            if (typeof element.nPets !== "undefined" && element.nPets) {
                                                NumberPeopleOnWeek = NumberPeopleOnWeek + element.nPets;
                                            }
                                        }
                                    });

                                    var maximo = 0;
                                    var menor = 5000000000;

                                    for (let i = 0; i < arrayXDateCapacity.length; i++) {
                                        var fechaEjeX = new Date(arrayXDateCapacity[i]);
                                        fechaEjeX.setSeconds(20000);
                                        var fechaFinal = `${fechaEjeX.getFullYear()}-${
                                            fechaEjeX.getMonth() + 1
                                        }-${fechaEjeX.getDate()}`;
                                        arrayDates.push(fechaFinal);

                                        /* Día más visitado */
                                        if (arrayY2InChartBarPets[i] > maximo) {
                                            maximo = arrayY2InChartBarPets[i];
                                            dateMax = fechaFinal;
                                        }

                                        /* día menos visitado Hombres*/
                                        if (arrayY2InChartBarPets[i] <= menor) {
                                            menor = arrayY2InChartBarPets[i];
                                            dateMin = fechaFinal;
                                        }
                                    }

                                    this.setState({
                                        valueLabel3: maximo,
                                        valueLabel4: menor,
                                        valueLabel5: dateMax,
                                        valueLabel6: dateMin,
                                    });
                                    this.setState({ valueLabel1: NumberPeopleOnWeek, valueLabel2: 0 });
                                } else {
                                    document.getElementById("chartBar").style.display = "none";
                                    document.getElementById("loadingBarChartIn").style.display = "";
                                    document.getElementById("loadingBarChartHistoric").style.display = "";
                                }

                                configStateBar = {
                                    type: "bar",
                                    options: {
                                        maintainAspectRatio: false,
                                        responsive: true,
                                        scales: {
                                            yAxes: [
                                                {
                                                    ticks: {
                                                        beginAtZero: true,
                                                        stepSize: 100,
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                    data: {
                                        labels: arrayDates,
                                        datasets: [
                                            {
                                                label: ["Mascotas"],
                                                data: arrayY2InChartBarPets,
                                                backgroundColor: "#7DAB22",
                                                borderColor: "#7DAB22",
                                                borderWidth: 3,
                                            },
                                        ],
                                    },
                                };

                                if (isUpdate) {
                                    let tmpBar = this.state.barGraphic;
                                    tmpBar.data.labels = configStateBar.data.labels;
                                    tmpBar.data.datasets = configStateBar.data.datasets;
                                    tmpBar.update();
                                    this.setState({
                                        barGraphic: tmpBar,
                                        loadingStaticInBar: false,
                                        loadingStaticHistoric: false,
                                    });
                                } else {
                                    let bar = document.getElementById("chartBar").getContext("2d");
                                    let chartBarIn = new Chart(bar, configStateBar);

                                    this.setState({
                                        barGraphic: chartBarIn,
                                        loadingStaticInBar: false,
                                        loadingStaticHistoric: false,
                                    });
                                }
                            });
                        } else {
                            console.log("Error" + response);
                        }
                    })
                    .catch(error => console.log("error ", error));
                let finishDateNow = new Date().getTime();

                let arrayCalendar = [];
                let arrayDataCalendar = [];

                API.get(
                    "https://dot",
                    "/analytics/resume-traffic/" +
                        "?groupId=" +
                        localStorage.getItem("idGroup") +
                        "&start=" +
                        startDateFormat +
                        "&end=" +
                        finishDateFormat
                )
                    .then(response => {
                        if (response.ok) {
                            response.json().then(data => {
                                if (data.length !== 0) {
                                    document.getElementById("chartCalendar").style.display = "none";
                                    document.getElementById("loadingChartCalendar").style.display = "none";
                                    document.getElementById("loadingBarCalendar").style.display = "";

                                    this.setState({ datosTotales: data.length });
                                    const archivosTotales = this.state.datosTotales;
                                    var porcentaje = 100 / this.state.datosTotales;
                                    data = data;
                                    const self = this;

                                    for (let i = 0; i < archivosTotales; i++) {
                                        document.getElementById("loadingBarCalendar").style.display = "";
                                        setTimeout(function () {
                                            data.forEach(element => {
                                                if (element.come_out !== undefined) {
                                                    arrayCalendar.push({
                                                        day: element.fecha,
                                                        value: element.come_out,
                                                    });
                                                }
                                            });

                                            var conversion = new Date(arrayCalendar[i].day);
                                            var valor = arrayCalendar[i].value;

                                            if (conversion.getMonth() < 9) {
                                                if (conversion.getDate() < 9) {
                                                    let completeDay = `${conversion.getFullYear()}-0${
                                                        conversion.getMonth() + 1
                                                    }-0${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                } else {
                                                    let completeDay = `${conversion.getFullYear()}-0${
                                                        conversion.getMonth() + 1
                                                    }-${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                }
                                            } else {
                                                if (conversion.getDate() < 9) {
                                                    let completeDay = `${conversion.getFullYear()}-${
                                                        conversion.getMonth() + 1
                                                    }-0${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                } else {
                                                    let completeDay = `${conversion.getFullYear()}-${
                                                        conversion.getMonth() + 1
                                                    }-${conversion.getDate()}`;
                                                    arrayDataCalendar.push({
                                                        day: completeDay,
                                                        value: valor,
                                                    });
                                                }
                                            }

                                            self.setState({
                                                progress: self.state.progress + porcentaje,
                                                datosTotales: self.state.datosTotales - 1,
                                            });

                                            if (i == archivosTotales - 1) {
                                                document.getElementById("chartCalendar").style.display = "";
                                                document.getElementById("loadingBarCalendar").style.display = "none";
                                                self.setState({ progress: 0.0 });
                                            }
                                        }, i);
                                    }

                                    this.setState({
                                        //loadingCalendar: false,
                                        dataCalendar: arrayDataCalendar,
                                    });
                                } else {
                                    document.getElementById("chartCalendar").style.display = "none";
                                    document.getElementById("loadingChartCalendar").style.display = "";
                                    document.getElementById("loadingBarCalendar").style.display = "none";

                                    this.setState({
                                        loadingCalendar: false,
                                    });
                                }
                            });
                        }
                    })
                    .catch(error => console.log("error ", error));
            }
        }
    }

    handleClickGroup = e => {
        localStorage.setItem("idGroup", e.target.id);
        localStorage.setItem("nameGroup", e.target.name);
        //this.getListCameras();
        //this.getListGroups();

        this.updateDashboardStatic(
            e.target.id,
            e.target.name,
            true,
            new Date().getTime(),
            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
        );
        //this.setState({ capacity: "0" });
    };

    handleClickTypeAlgorithm = e => {
        localStorage.setItem("typeAlgorithm", e.target.name);
        localStorage.setItem("idAlgorithm", e.target.id);
        this.setState({ dataType: e.target.name });
        this.updateDashboardStatic(
            e.target.id,
            e.target.name,
            true,
            new Date().getTime(),
            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
        );
        //this.setState({ capacity: "0" });
    };

    handleClickTypeMonth = e => {
        localStorage.setItem("specificMonth", e.target.name);

        this.updateDashboardStatic(
            e.target.id,
            e.target.name,
            true,
            new Date().getTime(),
            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
        );
        //this.setState({ capacity: "0" });
    };

    getListGroups() {
        API.get("https://dot", "/resource_group_specific/?model=ML-D11")
            .then(response => {
                var bBandera = true;
                if (response.ok) {
                    let global_item;
                    response.json().then(data => {
                        data.forEach(element => {
                            // convert element.id to string

                            let item = (
                                <Dropdown.Item
                                    onClick={this.handleClickGroup}
                                    className="Camera"
                                    id={element.id}
                                    key={element.id}
                                    name={element.name}
                                >
                                    {element.name}
                                </Dropdown.Item>
                            );

                            if (bBandera) {
                                let item = (
                                    <Dropdown.Item
                                        onClick={this.handleClickGroup}
                                        className="Camera"
                                        id={"0d8c4933-111e-4544-b098-82f3f6001e3c"}
                                        key={"0d8c4933-111e-4544-b098-82f3f6001e3c"}
                                        name={"Todos los accesos"}
                                    >
                                        {"Todos los accesos"}
                                    </Dropdown.Item>
                                );

                                let item_algorithm = (
                                    <Dropdown.Item
                                        onClick={this.handleClickTypeAlgorithm}
                                        className="Camera"
                                        id={"traffic"}
                                        key={"traffic"}
                                        name={"Trafico"}
                                    >
                                        {"Trafico"}
                                    </Dropdown.Item>
                                );

                                this.state.typeAlgorithm.push(item_algorithm);

                                item_algorithm = (
                                    <Dropdown.Item
                                        onClick={this.handleClickTypeAlgorithm}
                                        className="Camera"
                                        id={"characterization"}
                                        key={"characterization"}
                                        name={"Caracterización"}
                                    >
                                        {"Caracterización"}
                                    </Dropdown.Item>
                                );

                                this.state.typeAlgorithm.push(item_algorithm);

                                item_algorithm = (
                                    <Dropdown.Item
                                        onClick={this.handleClickTypeAlgorithm}
                                        className="Camera"
                                        id={"pets"}
                                        key={"pets"}
                                        name={"Mascotas"}
                                    >
                                        {"Mascotas"}
                                    </Dropdown.Item>
                                );

                                this.state.typeAlgorithm.push(item_algorithm);

                                let item_month = (
                                    <Dropdown.Item
                                        onClick={this.handleClickTypeMonth}
                                        className="Camera"
                                        id={"actual_month"}
                                        key={"actual_month"}
                                        name={"Este mes"}
                                    >
                                        {"Este mes"}
                                    </Dropdown.Item>
                                );

                                this.state.specificMonth.push(item_month);

                                item_month = (
                                    <Dropdown.Item
                                        onClick={this.handleClickTypeMonth}
                                        className="Camera"
                                        id={"last_month"}
                                        key={"last_month"}
                                        name={"Mes Anterior"}
                                    >
                                        {"Mes Anterior"}
                                    </Dropdown.Item>
                                );

                                this.state.specificMonth.push(item_month);

                                this.state.groups.push(item);
                                bBandera = false;
                            }

                            this.state.groups.push(item);
                        });
                        this.setState({
                            loading: false,
                        });

                        localStorage.setItem("idGroup", this.state.groups[0].key);
                        localStorage.setItem("nameGroup", this.state.groups[0].props.name);
                        localStorage.setItem("idAlgorithm", this.state.typeAlgorithm[0].key);
                        localStorage.setItem("typeAlgorithm", this.state.typeAlgorithm[0].props.name);
                        localStorage.setItem("specificMonth", this.state.specificMonth[0].props.name);

                        this.getAforo();

                        this.updateDashboardStatic(
                            localStorage.getItem("idGroup"),
                            localStorage.getItem("nameGroup"),
                            false,
                            new Date().getTime(),
                            new Date().getTime() - 1 * 3600 * 1000
                        );
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    verifyCaracterizacion() {
        const cookies = new Cookies();
        let menu = cookies.get("modules");
        let i = 0;
        while (i < menu.length) {
            if (menu[i] == "item-characterization") {
                document.getElementById("Caracterizacion1").style.display = "";
                document.getElementById("Caracterizacion2").style.display = "";
                document.getElementById("noCaracterizacion1").style.display = "none";
                document.getElementById("noCaracterizacion2").style.display = "none";
                break;
            }
            i++;
        }
    }

    getAforoGeneral() {
        this.setState({
            loadingAforoGeneral: true,
        });

        let count = 0;
        let nCountAforo = 0;

        if (this.state["groups"][1].key == "e90124bc-cd5e-4570-8313-6b6392dedc96") {
            this.setState({ capacityTotal: 3500 });
        }

        API.get("https://dot", "/analytics/capacity?groupId=" + this.state["groups"][1].key)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        const capacity_access = data.response.split(";");
                        count = capacity_access[0];
                        this.setState({ capacityGeneral: count }); // Check if this thing improve results.
                        this.setState({ valueLabel2: capacity_access[1] });
                        try {
                            if (count !== 0) {
                                let porcentajeCount = ((count * 100) / this.state.capacityTotal).toFixed(2);
                                if (porcentajeCount < 101) {
                                    this.setState({
                                        loadingAforoMax: false,
                                        porcentajeCapacity: porcentajeCount,
                                    });
                                } else {
                                    this.setState({
                                        loadingAforoMax: true,
                                        porcentajeCapacity: "Aforo Máximo ",
                                    });
                                }
                            } else {
                                this.setState({
                                    loadingAforoMax: false,
                                    porcentajeCapacity: 0,
                                });
                            }
                        } catch (e) {}

                        this.setState({
                            loadingAforoGeneral: false,
                        });
                    });
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    componentDidMount() {
        document.title = "Switch AI | Home";
        this.getListGroups();
        this.hideObjects();
        this.getListCameras(this.state.pagination);
        this.getAforo();
        this.intervalId = setInterval(
            () =>
                this.updateDashboardStatic(
                    localStorage.getItem("idGroup"),
                    localStorage.getItem("nameGroup"),
                    true,
                    new Date().getTime(),
                    new Date().getTime() - 1 * 3600 * 1000
                ),
            300000
        );

        this.verifyCaracterizacion();
        this.setState({ dateFrom: `${this.state.year}-01-31`, dateTo: `${this.state.year}-12-31` });
    }

    hideObjects() {
        document.getElementById("loadingBarChartIn").style.display = "none";
        document.getElementById("loadingBarChartHistoric").style.display = "none";

        document.getElementById("loadingChartCalendar").style.display = "none";
        document.getElementById("chartCalendar").style.display = "none";
        document.getElementById("loadingBarCalendar").style.display = "";
    }

    getAforo() {
        API.get("https://dot", "/analytics/capacity?groupId=" + localStorage.getItem("idGroup"))
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        //const capacity_access = data.response.split(';');
                        //this.setState({ capacity: capacity_access[0] });

                        this.setState({ capacity: data.response });
                    });
                    //console.log(contar++)
                } else {
                    console.log("Error" + response);
                }
            })
            .catch(error => console.log(error));
    }

    closeSideBar() {
        document.getElementById("lateralMenu").style.display = "none";
        document.getElementById("genericBody").style.filter = "none";
        document.getElementById("sub-menu").style.display = "none";
        document.getElementById("menuIcon2").style.display = "none";
        document.getElementById("menuIcon1").style.display = "";
        document.getElementById("lateralMenu").style.animation = "0.3s ease 0s 1 normal none running fadeOut";
    }

    render() {
        const showCharacterizationData = this.state.dataType === "Caracterización" && this.state.characterizationData?.data;

        const isColina = localStorage["Email"] === COLINA_EMAIL;

        return (
            <div>
                <NavMenuBar></NavMenuBar>
                <div id="genericBody" onClick={this.closeSideBar}>
                    <div className="Body-Home">
                        <Row>
                            <Col lg={1}></Col>
                            <Col lg={10}>
                                <div>
                                    <div className="Div-Justify">
                                        <Row>
                                            <Col lg={4}>
                                                <div className="form-inline">
                                                    <span className="Title-greeting">
                                                        Hola, {localStorage.getItem("UserName")}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row>
                                            <Col lg={1}></Col>
                                            {!isColina && (
                                                <>
                                                    <Col lg={3} className="Card-insight mr-4 mb-3">
                                                        <Row style={{ minHeight: "6rem" }}>
                                                            <Col lg={12} className="m-1">
                                                                <Row className="pt-3" style={{ margin: "auto" }}>
                                                                    <label className="label-Option-insight">
                                                                        {" "}
                                                                        {"En este momento el aforo es"}
                                                                    </label>
                                                                </Row>
                                                                <Row style={{ justifyContent: "center" }}>
                                                                    <div>
                                                                        <label className="Insight-Home">
                                                                            {this.state.capacityGeneral}
                                                                        </label>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={3} className="Card-insight mr-4 mb-3">
                                                        <Row style={{ minHeight: "6rem" }}>
                                                            <Col lg={12} className="m-1">
                                                                <Row className="pt-3" style={{ margin: "auto" }}>
                                                                    <label className="label-Option-insight">
                                                                        {" "}
                                                                        {"El promedio de aforo es"}
                                                                    </label>
                                                                </Row>
                                                                <Row style={{ justifyContent: "center" }}>
                                                                    <div>
                                                                        <label className="Insight-Home">
                                                                            {this.state.porcentajeCapacity + " %"}
                                                                        </label>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </>
                                            )}
                                            <Col lg={3} className="Card-insight mr-4 mb-3">
                                                <Row style={{ minHeight: "6rem" }}>
                                                    <Col lg={12} className="m-1">
                                                        <Row className="pt-3" style={{ margin: "auto" }}>
                                                            <label className="label-Option-insight"> {this.state.label2}</label>
                                                        </Row>
                                                        <Row style={{ justifyContent: "center" }}>
                                                            <div>
                                                                <label className="Insight-Home">{this.state.valueLabel2}</label>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="dark" id="dropdown-basic" className="Camera-Button">
                                                    {localStorage.getItem("typeAlgorithm")}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>{this.state.typeAlgorithm}</Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" id="dropdown-basic" className="Camera-Button">
                                                    {localStorage.getItem("nameGroup")}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>{this.state.groups}</Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="light" id="dropdown-basic" className="Camera-Button">
                                                    {localStorage.getItem("specificMonth")}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>{this.state.specificMonth}</Dropdown.Menu>
                                            </Dropdown>
                                        </Row>
                                    </div>
                                    <div id="Configuration" className="Container-Configuration">
                                        <Row>
                                            <Col lg={12}>
                                                {showCharacterizationData && (
                                                    <div className="characterization-chart">
                                                        <h2 className="characterization-title">
                                                            ¿Cuántas personas han ingresado durante el mes seleccionado?
                                                        </h2>
                                                        <StackedBarChart {...this.state.characterizationData} />
                                                    </div>
                                                )}
                                                <div
                                                    className={`Container-Layout-Home ${
                                                        showCharacterizationData ? "invisible" : ""
                                                    }`}
                                                >
                                                    <label className="label-Option">
                                                        ¿Cuántas {this.state.dataType === "Mascotas" ? "mascotas" : "personas"}
                                                        &nbsp;han ingresado durante el mes seleccionado?
                                                    </label>
                                                    <div className="Load">
                                                        {this.state.loadingStaticInBar && <div className="loader"></div>}
                                                    </div>
                                                    <canvas id="chartBar" className="pb-4"></canvas>
                                                    <div id="loadingBarChartIn" className="Div-Center">
                                                        <br></br>
                                                        <br></br>
                                                        <img src={working} width="80rem" alt="Imagen de Loading"></img>
                                                        <br></br>
                                                        <br></br>
                                                        <label className="Text-Message">Estamos procesando tus datos</label>
                                                        <br></br>
                                                        <label className="Text-Message">
                                                            Pronto tendremos estadísticas para ti
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={12} className="pt-3">
                                                <Row>
                                                    <Col lg={1}></Col>
                                                    <Col lg={3} className="Card-insight mr-4 mb-3">
                                                        <Row style={{ minHeight: "6rem" }}>
                                                            <Col lg={12} className="m-1">
                                                                <Row className="pt-3" style={{ margin: "auto" }}>
                                                                    <label className="label-Option-insight">
                                                                        {" "}
                                                                        {this.state.label1}
                                                                    </label>
                                                                </Row>
                                                                <Row style={{ justifyContent: "center" }}>
                                                                    <div>
                                                                        <label className="Insight-Home">
                                                                            {this.state.valueLabel1}
                                                                        </label>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={3} className="Card-insight mr-4 mb-3">
                                                        <Row style={{ minHeight: "6rem" }}>
                                                            <Col lg={12} className="m-1">
                                                                <Row className="pt-3" style={{ margin: "auto" }}>
                                                                    <label className="label-Option-insight">
                                                                        {" "}
                                                                        {this.state.label2}
                                                                    </label>
                                                                </Row>
                                                                <Row style={{ justifyContent: "center" }}>
                                                                    <div>
                                                                        <label className="Insight-Home">
                                                                            {this.state.valueLabel2}
                                                                        </label>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={3} className="Card-insight mr-4 mb-3">
                                                        <Row style={{ minHeight: "6rem" }}>
                                                            <Col lg={12} className="m-1">
                                                                <Row className="pt-3" style={{ margin: "auto" }}>
                                                                    <label className="label-Option-insight">
                                                                        {" "}
                                                                        {this.state.label3}
                                                                    </label>
                                                                </Row>
                                                                <Row style={{ justifyContent: "center" }}>
                                                                    <div>
                                                                        <label className="Insight-Home">
                                                                            {this.state.valueLabel3}
                                                                        </label>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} className="pt-3">
                                                <Row>
                                                    <Col lg={1}></Col>
                                                    <Col lg={3} className="Card-insight mr-4 mb-3">
                                                        <Row style={{ minHeight: "6rem" }}>
                                                            <Col lg={12} className="mx-2">
                                                                <Row className="pt-3" style={{ margin: "auto" }}>
                                                                    <label className="label-Option-insight">
                                                                        {" "}
                                                                        {this.state.label4}
                                                                    </label>
                                                                </Row>
                                                                <Row style={{ justifyContent: "center" }}>
                                                                    <div>
                                                                        <label className="Insight-Home">
                                                                            {this.state.valueLabel4}
                                                                        </label>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col lg={3} className="Card-insight mr-4 mb-3" id="noCaracterizacion1">
                                                        <Row style={{ minHeight: "6rem" }}>
                                                            <Col lg={12} className="mx-2">
                                                                <Row className="pt-3" style={{ margin: "auto" }}>
                                                                    <label
                                                                        className="label-Option-insight"
                                                                        style={{ margin: "auto" }}
                                                                    >
                                                                        {" "}
                                                                        {this.state.label5}
                                                                    </label>
                                                                </Row>
                                                                <Row style={{ justifyContent: "center" }}>
                                                                    <div>
                                                                        <label
                                                                            className="Insight-Home"
                                                                            style={{ margin: "auto" }}
                                                                        >
                                                                            {this.state.valueLabel5}
                                                                        </label>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={3} className="Card-insight mr-4 mb-3" id="noCaracterizacion2">
                                                        <Row style={{ minHeight: "6rem" }}>
                                                            <Col lg={12} className="mx-2">
                                                                <Row className="pt-3" style={{ margin: "auto" }}>
                                                                    <label
                                                                        className="label-Option-insight"
                                                                        style={{ margin: "auto" }}
                                                                    >
                                                                        {" "}
                                                                        {this.state.label6}
                                                                    </label>
                                                                </Row>
                                                                <Row style={{ justifyContent: "center" }}>
                                                                    <div>
                                                                        <label
                                                                            className="Insight-Home"
                                                                            style={{ margin: "auto" }}
                                                                        >
                                                                            {this.state.valueLabel6}
                                                                        </label>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="Container-Layout-Home">
                                                    <label className="label-Option">
                                                        {" "}
                                                        Tráfico de visitantes | Comparativo semanas del mes seleccionado
                                                    </label>
                                                    <div className="Load">
                                                        {this.state.loadingStaticHistoric && <div className="loader"></div>}
                                                    </div>
                                                    <canvas id="chartBarHistoric" className="pb-4"></canvas>

                                                    <div id="loadingBarChartHistoric" className="Div-Center">
                                                        <br></br>
                                                        <br></br>
                                                        <img src={working} width="80rem" alt="Imagen de Loading"></img>
                                                        <br></br>
                                                        <br></br>
                                                        <label className="Text-Message">Estamos procesando tus datos</label>
                                                        <br></br>
                                                        <label className="Text-Message">
                                                            Pronto tendremos estadísticas para ti
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}>
                                                <div className="Container-Box-Home">
                                                    <label className="label-Option">
                                                        {" "}
                                                        ¿Cuántos visitantes han ingresado a tu establecimiento?
                                                    </label>
                                                    <div id="loadingBarCalendar" className="mt-2">
                                                        {this.state.loadingCalendar && (
                                                            <ProgressBar
                                                                animated
                                                                variant="success"
                                                                max={100}
                                                                now={this.state.progress}
                                                            />
                                                        )}
                                                        <span className="ProgressText">
                                                            Estamos procesando tus datos. En un momento verás tu información
                                                        </span>
                                                    </div>

                                                    <div id="chartCalendar" style={{ height: 300, display: "block" }}>
                                                        <ResponsiveCalendarCanvas
                                                            data={this.state.dataCalendar}
                                                            from={this.state.dateFrom}
                                                            to={this.state.dateTo}
                                                            emptyColor="#EFEFEF"
                                                            colors={["#606060", "#323232", "#1a3b5a", "#86ac36", "#beea53"]}
                                                            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                                                            yearSpacing={40}
                                                            monthBorderColor="#ffffff"
                                                            dayBorderWidth={2}
                                                            dayBorderColor="#ffffff"
                                                            legends={[
                                                                {
                                                                    anchor: "bottom-right",
                                                                    direction: "row",
                                                                    translateY: 36,
                                                                    itemCount: 4,
                                                                    itemWidth: 42,
                                                                    itemHeight: 36,
                                                                    itemsSpacing: 14,
                                                                    itemDirection: "right-to-left",
                                                                },
                                                            ]}
                                                        />
                                                    </div>

                                                    <div id="loadingChartCalendar" className="Div-Center">
                                                        <br></br>
                                                        <br></br>
                                                        <img src={working} width="80rem" alt="Imagen de Loading"></img>
                                                        <br></br>
                                                        <br></br>
                                                        <label className="Text-Message">Estamos procesando tus datos</label>
                                                        <br></br>
                                                        <label className="Text-Message">
                                                            Pronto tendremos estadísticas para ti
                                                        </label>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={1}></Col>
                        </Row>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Home;
