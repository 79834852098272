//Import main libraries and components
import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Row, Col, Button } from "react-bootstrap";
import Cookies from "universal-cookie";
import API from "../api/Main";
import ReactTooltip from "react-tooltip";

//Import images
import logo from "../imgs/green-logo.png";
import menuOff from "../imgs/toggleoff.png";
import menuOn from "../imgs/toggleon.png";
import home from "../imgs/logo7.png";
import biosecurity from "../imgs/logo6.png";
import dashboard from "../imgs/logo3.png";
import camera from "../imgs/logo12.png";
import retail from "../imgs/logo8.png";
import configuration from "../imgs/logo1.png";
import noNotification from "../imgs/checknotificaciones.png";
import notification from "../imgs/notificaciones.png";
import symptoms from "../imgs/logo4.png";
import logout from "../imgs/logout.png";
import feedback from "../imgs/question.png";
import imgEquis from "../imgs/equis2.png";
import imgChat from "../imgs/chat.png";
import imgHappyFace from "../imgs/Happy-face.png";
import imgLoveFace from "../imgs/love-face.png";
import imgNeutralFace from "../imgs/neutral-face.png";
import imgSadFace from "../imgs/Sad-Face.png";
import imgAngryFace from "../imgs/angry-face.png";
import resourcesGroup from "../imgs/logo10.png";
import perfil_dark from "../imgs/logo9.png";
import perfil_light from "../imgs/logo11.png";
import caracterizacion from "../imgs/Caracterizacion.png";
import comportamiento from "../imgs/Comportamiento.png";
import supervision from "../imgs/Supervision.png";
import analiticas from "../imgs/analitica.png";

//Import styles
import "../styles/Shared/navbar.css";
import "../styles/navbar.scss";
import { USER_ALIAS } from "constants/Customer";

class NavMenuBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            module: "",
            switch: 0,
            switch_notification: 1,
            switch_perfil: 1,
            submenuOrientation: "",
            title: "",
            text: "",
            url_screenshot: "",
            create_date: "",
            loading: false,
            count: 0,
            notifications: [],
            isOpen: false,
            sideopen: false,
            tooltipOpen: false,
        };
    }
    drawerOpen = () => {
        this.setState(prevState => {
            return { sideopen: !prevState.sideopen };
        });
    };

    toggle = () => {
        this.setState({ tooltipOpen: true });
    };

    loadMenu() {
        const cookies = new Cookies();
        let menu = cookies.get("modules");
        let role = cookies.get("role");
        this.hideItems();
        for (var i = 0; i < menu.length; i++) {
            if (menu[i] == "item-traffic") {
                document.getElementById("item-traffic").style.display = "";
            } else if (menu[i] == "item-characterization") {
                document.getElementById("item-characterization").style.display = "";
            } else if (menu[i] == "item-security") {
                document.getElementById("item-home-security").style.display = "";
                document.getElementById("item-security").style.display = "";
                document.getElementById("item-configuration").style.display = "";
            } else if (menu[i] == "item-behaviour") {
                document.getElementById("item-behaviour").style.display = "";
            } else if (menu[i] == "item-dashboard-general") {
                document.getElementById("item-trafic-TR-manager").style.display = "";
                document.getElementById("item-trafic-H-manager").style.display = "";
                document.getElementById("item-supervision").style.display = "";
                document.getElementById("item-configuration").style.display = "";
            } else if (menu[i] == "item-dashboard-manager") {
                document.getElementById("item-trafic-TR-general").style.display = "";
                document.getElementById("item-trafic-H-general").style.display = "";
                document.getElementById("item-configuration").style.display = "";
            }
        }
        if (!(menu[0] == "item-security")) {
            if (role == "owner") {
                document.getElementById("item-home").style.display = "";
            }
            if (role == "admin") {
                document.getElementById("item-home-admin").style.display = "";
            }
        }
        if (role === "owner" && (menu[i] === "item-dashboard-general" || menu[i] === "item-dashboard-manager")) {
            document.getElementById("activeConfig").style.display = "none";
            document.getElementById("item-security").style.display = "none";
            document.getElementById("item-sympthoms").style.display = "none";
            document.getElementById("item-home-security").style.display = "none";
        }
    }

    hideItems() {
        document.getElementById("item-configuration").style.display = "none";
        document.getElementById("item-characterization").style.display = "none";
        document.getElementById("item-traffic").style.display = "none";
        document.getElementById("item-behaviour").style.display = "none";
        document.getElementById("item-analytics").style.display = "none";
        document.getElementById("item-supervision").style.display = "none";
        document.getElementById("item-security").style.display = "none";
        document.getElementById("item-home").style.display = "none";
        document.getElementById("item-home-admin").style.display = "none";
        document.getElementById("item-sympthoms").style.display = "none";
        document.getElementById("item-home-security").style.display = "none";
        document.getElementById("item-trafic-TR-general").style.display = "none";
        document.getElementById("item-trafic-H-general").style.display = "none";
        document.getElementById("item-trafic-TR-manager").style.display = "none";
        document.getElementById("item-trafic-H-manager").style.display = "none";
        //document.getElementById("item-dashboard-super").style.display = "none";
    }

    openFeedback = () => {
        document.getElementById("popUpFeedback").style.display = "";
        document.getElementById("genericBody").style.filter = "blur(5px)";
        document.getElementById("activeFeedback").style.color = "rgb(70, 67, 67)";
        document.getElementById("activeFeedback").style.borderStyle = "dashed";
        document.getElementById("activeFeedback").style.borderColor = "#84ac34";
        document.getElementById("activeFeedback").style.borderRadius = "1rem";
    };

    handleClosePopUp = () => {
        document.getElementById("popUpFeedback").style.display = "none";
        document.getElementById("genericBody").style.filter = "";
        document.getElementById("message").style.display = "none";
        document.getElementById("sendButton").style.display = "";
        document.getElementById("activeFeedback").style.color = "";
        document.getElementById("activeFeedback").style.borderStyle = "none";
        this.setState({ text: "" });
    };

    handleClickNotification = () => {
        this.verifyClic();
        this.createNotification();
    };

    openPerfil = () => {
        if (this.state.switch_perfil === 1) {
            document.getElementById("perfil").style.display = "";
            this.setState({ switch_perfil: 0 });
        } else if (this.state.switch_perfil === 0) {
            document.getElementById("perfil").style.display = "none";
            this.setState({ switch_perfil: 1 });
        }
    };

    handleChangeFeeling(m) {
        this.setState({ title: m });
        if (m === "Muy Insatisfecho") {
            document.getElementById("emoji1").style.backgroundColor = "#D6D8D2";
            document.getElementById("emoji2").style.backgroundColor = "";
            document.getElementById("emoji3").style.backgroundColor = "";
            document.getElementById("emoji4").style.backgroundColor = "";
            document.getElementById("emoji5").style.backgroundColor = "";
        } else if (m === "Insatisfecho") {
            document.getElementById("emoji1").style.backgroundColor = "";
            document.getElementById("emoji2").style.backgroundColor = "#D6D8D2";
            document.getElementById("emoji3").style.backgroundColor = "";
            document.getElementById("emoji4").style.backgroundColor = "";
            document.getElementById("emoji5").style.backgroundColor = "";
        } else if (m === "Neutral") {
            document.getElementById("emoji1").style.backgroundColor = "";
            document.getElementById("emoji2").style.backgroundColor = "";
            document.getElementById("emoji3").style.backgroundColor = "#D6D8D2";
            document.getElementById("emoji4").style.backgroundColor = "";
            document.getElementById("emoji5").style.backgroundColor = "";
        } else if (m === "Satisfecho") {
            document.getElementById("emoji1").style.backgroundColor = "";
            document.getElementById("emoji2").style.backgroundColor = "";
            document.getElementById("emoji3").style.backgroundColor = "";
            document.getElementById("emoji4").style.backgroundColor = "#D6D8D2";
            document.getElementById("emoji5").style.backgroundColor = "";
        } else {
            document.getElementById("emoji1").style.backgroundColor = "";
            document.getElementById("emoji2").style.backgroundColor = "";
            document.getElementById("emoji3").style.backgroundColor = "";
            document.getElementById("emoji4").style.backgroundColor = "";
            document.getElementById("emoji5").style.backgroundColor = "#D6D8D2";
        }
    }

    handleChangeMessage = e => {
        this.setState({ text: e.target.value });
        document.getElementById("inputMessage").style.borderColor = "";
    };

    sendFeedback = () => {
        if (this.state.text !== "" && this.state.title !== "") {
            this.setState({ loading: true });
            let body = JSON.stringify({
                data: {
                    title: this.state.title,
                    text: this.state.text,
                    url_screenshot: this.state.url_screenshot,
                    create_date: new Date().toISOString(),
                },
            });
            API.post("https://dot", "/general/feedback", body)
                .then(response => {
                    if (response.ok) {
                        this.setState({ loading: false, title: "", text: "" });
                        document.getElementById("sendButton").style.display = "none";
                        document.getElementById("message").style.display = "";
                        document.getElementById("message").style.color = "#84ac34";
                        document.getElementById("message").innerText = "Comentario enviado con éxito";
                    } else {
                        this.setState({ loading: false });
                        document.getElementById("sendButton").style.display = "none";
                        document.getElementById("message").style.display = "";
                        document.getElementById("message").style.color = "rgb(226, 86, 86)";
                        document.getElementById("message").innerText = "Ha ocurrido un error. Por favor escribe a a@switchai.co";
                    }
                })
                .catch(error => console.log("error", error));
        } else {
            if (this.state.text === "" && this.state.title === "") {
                document.getElementById("inputMessage").style.borderColor = "rgb(226, 86, 86)";
                document.getElementById("InputFeeling").style.border = "solid 2px rgb(226, 86, 86)";
            } else if (this.state.text === "" && this.state.title !== "") {
                document.getElementById("inputMessage").style.borderColor = "rgb(226, 86, 86)";
            } else if (this.state.text !== "" && this.state.title === "") {
                document.getElementById("InputFeeling").style.border = "solid 2px rgb(226, 86, 86)";
            }
        }
    };

    handleLinkStores = () => {
        const cookies = new Cookies();
        let menu = cookies.get("modules");
        let role = cookies.get("role");
        if (menu[0] == "item-retail") {
            window.location = "/configuration/zones-selection";
        } else if (menu[0] == "item-security") {
            window.location = "/configuration/algorithm";
        }
    };

    handleLogoClick = () => {
        //window.location = "/home";
        const cookies = new Cookies();
        let menu = cookies.get("modules");
        let role = cookies.get("role");
        console.log("Pruebita", menu);
        if ((menu[0] == "item-retail") & (role == "admin")) {
            //window.location = "/marketing/dashboard";
            window.location = "/admin/home";
        } else if ((menu[0] == "item-retail") & (role == "owner")) {
            //window.location = "/marketing/general";
            window.location = "/admin/home";
        }
        if (menu[0] == "item-security") {
            //window.location = "/admin/homeSecurity";
            window.location = "/admin/homeSecurityDash";
        }
    };

    handleMenuClick = () => {
        this.verifySwitch();
    };

    verifySwitch() {
        if (this.state.switch === 0) {
            document.getElementById("menuIcon1").style.display = "";
            document.getElementById("menuIcon2").style.display = "none";
            document.getElementById("lateralMenu").style.animation = "fadeOut 0.3s";
            document.getElementById("lateralMenu").style.display = "none";
            document.getElementById("sub-menu").style.display = "none";
            document.getElementById("item-bioseguridad").style.display = "none";
            document.getElementById("item-count").style.display = "none";
            document.getElementById("item-sintomas").style.display = "none";
            document.getElementById("item-configuracion").style.display = "none";
            //document.getElementById("genericBody").style.filter = "none";
            //document.getElementById("menuIcon").style.borderColor = "#dae0e5";

            this.setState({ switch: 1 });
        } else if (this.state.switch === 1) {
            document.getElementById("menuIcon1").style.display = "none";
            document.getElementById("menuIcon2").style.display = "";
            document.getElementById("lateralMenu").style.animation = "fadeIn 0.3s";
            document.getElementById("lateralMenu").style.display = "";
            document.getElementById("sub-menu").style.display = "";
            //document.getElementById("menuIcon").style.borderColor = "#84ac34";
            //document.getElementById("genericBody").style.filter = "blur(5px)";

            this.setState({ switch: 0 });
        }
    }

    verifyDevice() {
        let agent = window.navigator.userAgent;
        let devices = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i; //Expresión regular de dispositivos móviles para usar en el match()
        //Usando el array que devuelve el userAgent se modifica la orientación del submenú
        if (agent.match(devices) !== null) {
            this.setState({ submenuOrientation: "bottom" });
        } else {
            this.setState({ submenuOrientation: "right" });
        }
    }

    doLogout = () => {
        const cookies = new Cookies();
        cookies.remove("token", { path: "/" });
        window.location = "/";
        //http://control.holasoysami.com/
    };

    verifyClic() {
        if (this.state.switch_notification === 0) {
            document.getElementById("notificationDiv").style.display = "none";
            document.getElementById("NoneNotification").style.display = "none";
            this.setState({ switch_notification: 1 });
        } else if (this.state.switch_notification === 1) {
            document.getElementById("notificationDiv").style.display = "";
            this.setState({ switch_notification: 0 });
        }
    }

    async createNotification() {
        const nameClass = document.getElementsByClassName("Notification").item(0).classList;
        let stateNotifications = this.state.notifications;
        try {
            if (nameClass.contains("NotificationVisible") === true) {
                nameClass.remove("NotificationVisible");
                this.setState({
                    isOpen: false,
                });
            } else {
                nameClass.add("NotificationVisible");
                const request = await API.get("https://dot", "/notifications/action/send/notification");
                await API.post(
                    "https://dot",
                    "/notifications/action/validate/open",
                    JSON.stringify({
                        data: {
                            response: true,
                        },
                    })
                );
                const response = await request.json();
                if (response.length === 0) {
                    document.getElementById("NoneNotification").style.display = "";
                } else {
                    response.sort((a, b) => {
                        return new Date(b.create_date) - new Date(a.create_date);
                    });
                    response.forEach(value => {
                        stateNotifications.push(
                            <a className="Notification__Item" href={value.url}>
                                <h3>{value.title}</h3>
                                <p>{value.body}</p>
                            </a>
                        );
                    });
                    this.setState({
                        notifications: stateNotifications,
                        isOpen: true,
                        count: 0,
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    async loadCount() {
        try {
            const request = await API.get("https://dot", "/notifications/action/send/notification");
            const data = await request.json();
            data.forEach(value => {
                if (value.see_date === null) {
                    this.setState({
                        count: this.state.count + 1,
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        this.verifySwitch();
        this.verifyDevice();
        this.loadMenu();
        this.loadCount();
        this.verifyModule();
        document.getElementById("popUpFeedback").style.display = "none";
        document.getElementsByClassName("Notification").item(0).classList.remove("NotificationVisible");
        document.getElementById("NoneNotification").style.display = "none";
        document.getElementById("notificationDiv").style.display = "none";
        document.getElementById("perfil").style.display = "none";
        document.getElementById("item-count").style.display = "none";
        document.getElementById("item-bioseguridad").style.display = "none";
        document.getElementById("item-sintomas").style.display = "none";
        document.getElementById("item-configuracion").style.display = "none";
        document.getElementById("menuIcon1").style.display = "";
        document.getElementById("menuIcon2").style.display = "none";
    }

    verifyModule() {
        const cookies = new Cookies();
        let menu = cookies.get("modules");
        let role = cookies.get("role");
        if (menu[0] == "item-retail") {
            this.setState({ module: "Retail" });
        } else if (menu[0] == "item-security") {
            this.setState({ module: "Bioseguridad" });
        }
    }

    openSubMenu = n => {
        if (n == 1) {
            document.getElementById("item-count").style.display = "none";
            document.getElementById("item-bioseguridad").style.display = "";
            document.getElementById("item-sintomas").style.display = "none";
            document.getElementById("item-configuracion").style.display = "none";

            /* document.getElementById("item-count").style.display = "";
      document.getElementById("item-bioseguridad").style.display = "none";
      document.getElementById("item-sintomas").style.display = "none";
      document.getElementById("item-configuracion").style.display = "none"; */
        }
        if (n == 2) {
            document.getElementById("item-count").style.display = "none";
            document.getElementById("item-bioseguridad").style.display = "none";
            document.getElementById("item-sintomas").style.display = "";
            document.getElementById("item-configuracion").style.display = "none";

            /* document.getElementById("item-count").style.display = "none";
      document.getElementById("item-bioseguridad").style.display = "";
      document.getElementById("item-sintomas").style.display = "none";
      document.getElementById("item-configuracion").style.display = "none"; */
        }
        if (n == 3) {
            document.getElementById("item-count").style.display = "";
            document.getElementById("item-bioseguridad").style.display = "none";
            document.getElementById("item-sintomas").style.display = "none";
            document.getElementById("item-configuracion").style.display = "none";

            /* document.getElementById("item-count").style.display = "none";
      document.getElementById("item-bioseguridad").style.display = "none";
      document.getElementById("item-sintomas").style.display = "";
      document.getElementById("item-configuracion").style.display = "none"; */
        }
        if (n == 4) {
            document.getElementById("item-count").style.display = "none";
            document.getElementById("item-bioseguridad").style.display = "none";
            document.getElementById("item-sintomas").style.display = "none";
            document.getElementById("item-configuracion").style.display = "";
        }
    };

    render() {
        const getName = () => {
            const user = localStorage["UserName"];
            return USER_ALIAS[user] ?? user;
        };
        const user = getName();

        return (
            <div>
                <div className="header">
                    <img
                        src={menuOff}
                        alt="Icono menú lateral"
                        width="40rem"
                        onClick={this.handleMenuClick}
                        id="menuIcon1"
                        className="Menu-Icon"
                    />
                    <img
                        src={menuOn}
                        alt="Icono menú lateral"
                        width="40rem"
                        onClick={this.handleMenuClick}
                        id="menuIcon2"
                        className="Menu-Icon"
                    />
                    <img src={logo} alt="Logo Switch AI" onClick={this.handleLogoClick} className="Switch-Icon"></img>
                    <div className="flex-center">
                        <Nav.Link
                            style={{ display: "inline-flex" }}
                            className="NavMenu-Item user-icon"
                            onClick={this.openPerfil}
                            id="activeFeedback"
                        >
                            <img src={perfil_dark} alt="Icono Feedback" width="40rem" className="Icon-Menu"></img>
                        </Nav.Link>
                    </div>

                    {/* </Navbar.Collapse> */}
                </div>
                <div id="lateralMenu" className="Lateral">
                    <div className="scroll">
                        <div className="scrollbar-container ps">
                            <ul className="list-unstyled nav flex-column" id="item-home">
                                <Nav.Link href="/admin/home">
                                    <li className="nav-item">
                                        <img src={home} alt="Icono Dashboard" width="55rem" className="Icon-Menu-Lateral"></img>
                                        <span className="Menu-Item-Title-2">Home</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-home-admin">
                                <Nav.Link href="/admin/home">
                                    <li className="nav-item">
                                        <img src={home} alt="Icono Dashboard" width="55rem" className="Icon-Menu-Lateral"></img>
                                        <span className="Menu-Item-Title-2">Home</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-home-security">
                                <Nav.Link href="/admin/homeSecurityDash">
                                    <li className="nav-item">
                                        <img src={home} alt="Icono Dashboard" width="55rem" className="Icon-Menu-Lateral"></img>
                                        <span className="Menu-Item-Title-2">Home</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-security">
                                <Nav.Link className="" onClick={() => this.openSubMenu(1)}>
                                    <li className="nav-item">
                                        <img
                                            src={biosecurity}
                                            alt="Icono Dashboard"
                                            width="55rem"
                                            className="Icon-Menu-Lateral"
                                        ></img>
                                        <span className="Menu-Item-Title-2">Seguridad</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-sympthoms">
                                <Nav.Link className="" onClick={() => this.openSubMenu(2)}>
                                    <li className="nav-item">
                                        <img
                                            src={symptoms}
                                            alt="Icono Dashboard"
                                            width="55rem"
                                            className="Icon-Menu-Lateral"
                                        ></img>
                                        <span className="Menu-Item-Title-2">Síntomas</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-traffic">
                                <Nav.Link className="" onClick={() => this.openSubMenu(3)}>
                                    <li className="nav-item">
                                        <img src={retail} alt="Icono Dashboard" width="55rem" className="Icon-Menu-Lateral"></img>
                                        <span className="Menu-Item-Title-2">Tráfico</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-behaviour">
                                <Nav.Link href="/schedule">
                                    <li className="nav-item">
                                        <i className="fa-solid fa-calendar-days mb-3 nav-icon" />
                                        <p className="Menu-Item-Title-2">Eventos</p>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-characterization">
                                <Nav.Link href="/marketing/characterization">
                                    <li className="nav-item">
                                        <img
                                            src={caracterizacion}
                                            alt="Icono Dashboard"
                                            width="38rem"
                                            className="Icon-Menu-Lateral"
                                        ></img>
                                        <span className="Menu-Item-Title-2">Caracterización</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-behaviour">
                                <Nav.Link href="/marketing/behaviour">
                                    <li className="nav-item">
                                        <img
                                            src={comportamiento}
                                            alt="Icono Dashboard"
                                            width="35rem"
                                            className="Icon-Menu-Lateral"
                                        ></img>
                                        <span className="Menu-Item-Title-2">Comportamiento</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column my-3" id="item-analytics">
                                <Nav.Link href="">
                                    <li className="nav-item">
                                        <img
                                            src={analiticas}
                                            alt="Icono Dashboard"
                                            width="40rem"
                                            className="Icon-Menu-Lateral"
                                        ></img>
                                        <span className="Menu-Item-Title-2">Analíticas</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column my-4" id="item-supervision">
                                <Nav.Link href="/admin/home">
                                    <li className="nav-item">
                                        <img
                                            src={supervision}
                                            alt="Icono Dashboard"
                                            width="45rem"
                                            className="Icon-Menu-Lateral"
                                        ></img>
                                        <span className="Menu-Item-Title-2">Super-visión</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-configuration">
                                <Nav.Link className="" onClick={() => this.openSubMenu(4)}>
                                    <li className="nav-item">
                                        <img
                                            src={configuration}
                                            alt="Icono Dashboard"
                                            width="55rem"
                                            className="Icon-Menu-Lateral"
                                        ></img>
                                        <span className="Menu-Item-Title-2">Configuración</span>
                                    </li>
                                </Nav.Link>
                            </ul>
                            {/* <ul className="list-unstyled nav flex-column" id="menu-item">  
                <Nav.Link className="" onClick={this.doLogout}>
                  <li className="nav-item">
                    <img
                      src={logout}
                      alt="Icono Logout"
                      width="30rem"
                      className="Icon-Menu-Lateral"
                    ></img>
                    <span className="Menu-Item-Title-2">Cerrar sesión</span>
                  </li>
                </Nav.Link>
              </ul>  */}
                        </div>
                    </div>
                </div>

                <div id="sub-menu">
                    <div className="scroll">
                        <div className="scrollbar-container ps">
                            <ul className="list-unstyled nav flex-column" id="item-count">
                                <li>
                                    <Nav.Link
                                        id="item-trafic-TR-general"
                                        className="Menu-Item-subMenu"
                                        href="/marketing/dashboard"
                                    >
                                        <img src={dashboard} alt="Icono Dashboard" width="30rem" className="Icon-Menu"></img>
                                        Tiempo Real general
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link
                                        id="item-trafic-H-general"
                                        className="Menu-Item-subMenu"
                                        href="/marketing/historical"
                                    >
                                        <img src={dashboard} alt="Icono Dashboard" width="30rem" className="Icon-Menu"></img>
                                        Histórico general
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link id="item-trafic-TR-manager" className="Menu-Item-subMenu" href="/marketing/super">
                                        <img src={dashboard} alt="Icono Dashboard" width="30rem" className="Icon-Menu"></img>
                                        Tiempo Real
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link
                                        id="item-trafic-H-manager"
                                        className="Menu-Item-subMenu"
                                        href="/marketing/historical"
                                    >
                                        <img src={dashboard} alt="Icono Dashboard" width="30rem" className="Icon-Menu"></img>
                                        Histórico
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link
                                        id="item-trafic-H-manager"
                                        className="Menu-Item-subMenu"
                                        href="/marketing/historical2"
                                    >
                                        <img src={dashboard} alt="Icono Dashboard" width="30rem" className="Icon-Menu" />
                                        Nuevo Histórico
                                    </Nav.Link>
                                </li>
                                <hr className="line" />
                                <li className="mb-1 pl-3">
                                    <Nav.Link
                                        id="item-trafic-H-manager"
                                        className="Menu-Item-subMenu"
                                        href="/marketing/reports"
                                    >
                                        <i className="fa-solid fa-chart-simple nav-icon--chart" />
                                        Informes
                                    </Nav.Link>
                                </li>

                                {/* 
                                <li className="pl-4 mb-1">
                                    <Nav.Link
                                        id="item-trafic-H-manager"
                                        className="Menu-Item-subMenu"
                                        href="#"
                                    >
                                        Exportar csv
                                    </Nav.Link>
                                </li>
                                <li className="pl-4">
                                    <Nav.Link
                                        id="item-trafic-H-manager"
                                        className="Menu-Item-subMenu"
                                        href="#"
                                    >
                                        Comparativo
                                    </Nav.Link>
                                </li> */}
                                {/* <li>
                  <Nav.Link
                    id="item-dashboard-manager"
                    className="Menu-Item-subMenu"
                    href="/marketing/dashboard"
                  >
                    <img
                      src={dashboard}
                      alt="Icono Dashboard"
                      width="30rem"
                      className="Icon-Menu"
                    ></img>
                    Visión General
                  </Nav.Link>
                </li>
                <li>
                    <Nav.Link
                    id="item-dashboard-general"
                    className="Menu-Item-subMenu"
                    href="/marketing/general"
                  >
                    <img
                      src={dashboard}
                      alt="Icono Dashboard"
                      width="30rem"
                      className="Icon-Menu"
                    ></img>
                    Visión General
                  </Nav.Link>
                </li>
                <li>
                <Nav.Link
                  id="item-dashboard-super"
                  className="Menu-Item-subMenu"
                  href="/marketing/super"
                >
                  <img
                    src={biosecurity}
                    alt="Icono Dashboard"
                    width="30rem"
                    className="Icon-Menu"
                  ></img>
                  Comportamiento Tienda
                </Nav.Link>
                </li>
                <li>
                  <Nav.Link
                    id="item-dashboard-manager"
                    className="Menu-Item-subMenu"
                    href="/marketing/historical"
                  >
                    <img
                      src={dashboard}
                      alt="Icono Dashboard"
                      width="30rem"
                      className="Icon-Menu"
                    ></img>
                    Histórico
                  </Nav.Link>
                </li>  */}
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-bioseguridad">
                                <li>
                                    <Nav.Link id="activeDashBio" className="Menu-Item-subMenu" href="/admin/homeSecurityDash">
                                        <img src={dashboard} alt="Icono Dashboard" width="30rem" className="Icon-Menu"></img>
                                        Dashboard
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link Col={12} id="activeCamsBio" className="Menu-Item-subMenu" href="/biodot/cameras">
                                        <img src={camera} alt="Icono Cameras" width="30rem" className="Icon-Menu"></img>
                                        Cámaras
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link id="activeConfigBio" className="Menu-Item-subMenu d-none" href="/biodot/account">
                                        <img
                                            src={configuration}
                                            alt="Icono Configuration"
                                            width="30rem"
                                            className="Icon-Menu"
                                        ></img>
                                        Configuración
                                    </Nav.Link>
                                </li>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-sintomas">
                                <li>
                                    <Nav.Link id="activeDashSym" className="Menu-Item-subMenu" href="/symptoms/dashboard">
                                        <img src={dashboard} alt="Icono Configuration" width="30rem" className="Icon-Menu"></img>
                                        Dashboard
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link id="activeAnsSym" className="Menu-Item-subMenu" href="/symptoms/answers">
                                        <img src={symptoms} alt="Icono Configuration" width="30rem" className="Icon-Menu"></img>
                                        Respuestas individuales
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link id="activeConfigSym" className="Menu-Item-subMenu" href="/symptoms/emails">
                                        <img
                                            src={configuration}
                                            alt="Icono Configuration"
                                            width="30rem"
                                            className="Icon-Menu"
                                        ></img>
                                        Configuración
                                    </Nav.Link>
                                </li>
                            </ul>
                            <ul className="list-unstyled nav flex-column" id="item-configuracion">
                                <li>
                                    <Nav.Link id="activeAdmin" className="Menu-Item-subMenu" href="/admin/admin-resources-group">
                                        <img
                                            src={resourcesGroup}
                                            alt="Icono Configuración"
                                            width="30rem"
                                            className="Icon-Menu"
                                        ></img>
                                        Administrar zonas
                                    </Nav.Link>
                                </li>
                                <li>
                                    <Nav.Link id="activeConfig" className="Menu-Item-subMenu" onClick={this.handleLinkStores}>
                                        <img
                                            src={configuration}
                                            alt="Icono Configuración"
                                            width="30rem"
                                            className="Icon-Menu"
                                        ></img>
                                        Algoritmos por Zonas
                                    </Nav.Link>
                                </li>
                                <li>
                                    {/* <Nav.Link
                                        id="activeConfig"
                                        href="/configuration/alerts"
                                        className="Menu-Item-subMenu"
                                        onClick={this.handleLinkStores}
                                    > */}
                                    {/* </Nav.Link> */}
                                    <Link to="/configuration/alerts">
                                        <i className="fa-solid fa-bell text-lg ml-2 mr-3" />
                                        Configuración de alertas
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <Row>
                    <Col lg={2}></Col>
                    <Col lg={8}>
                        <div id="popUpFeedback" className="Pop-Up-Feedback">
                            <Row className="Head-Modal">
                                <Col lg={1}></Col>
                                <Col lg={9} md={9} sm={9} xs={10}>
                                    <img src={imgChat} alt="Icono chat" width="40rem" className="icon-chat"></img>
                                    <p className="text-light Title-Comment"> Comentarios</p>
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={1} style={{ paddingTop: "0.5rem" }}>
                                    <img
                                        src={imgEquis}
                                        alt="Icono close"
                                        width="25rem"
                                        className="Equis"
                                        onClick={this.handleClosePopUp}
                                    ></img>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: "1rem" }}>
                                <Col lg={2}></Col>
                                <Col lg={8}>
                                    <div className="Load">{this.state.loading && <div className="loader"></div>}</div>
                                    <div className="Div-Left">
                                        <label className="Title-Feedback">¿Cómo te has sentido con la plataforma?</label>
                                        <br></br>
                                    </div>
                                    <div id="InputFeeling">
                                        <div
                                            id="emoji1"
                                            onClick={() => this.handleChangeFeeling("Muy Insatisfecho")}
                                            style={{
                                                display: "inline-block",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <img
                                                data-tip
                                                data-for="tooltipVeryUnsatisfied"
                                                src={imgAngryFace}
                                                alt="Icono Sad Face"
                                                width="50rem"
                                                className="Equis"
                                            ></img>
                                        </div>
                                        <div
                                            id="emoji2"
                                            onClick={() => this.handleChangeFeeling("Insatisfecho")}
                                            style={{
                                                display: "inline-block",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <img
                                                data-tip
                                                data-for="tooltipUnsatisfied"
                                                src={imgSadFace}
                                                alt="Icono Sad Face"
                                                width="50rem"
                                                className="Equis"
                                            ></img>
                                        </div>
                                        <div
                                            id="emoji3"
                                            onClick={() => this.handleChangeFeeling("Neutral")}
                                            style={{
                                                display: "inline-block",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <img
                                                data-tip
                                                data-for="tooltipNeutral"
                                                src={imgNeutralFace}
                                                alt="Icono Neutral Face"
                                                width="50rem"
                                                className="Equis"
                                            ></img>
                                        </div>
                                        <div
                                            id="emoji4"
                                            onClick={() => this.handleChangeFeeling("Satisfecho")}
                                            style={{
                                                display: "inline-block",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <img
                                                data-tip
                                                data-for="tooltipSatisfied"
                                                src={imgHappyFace}
                                                alt="Icono Happy Face"
                                                width="50rem"
                                                className="Equis"
                                            ></img>
                                        </div>
                                        <div
                                            id="emoji5"
                                            onClick={() => this.handleChangeFeeling("Muy Satisfecho")}
                                            style={{
                                                display: "inline-block",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <img
                                                data-tip
                                                data-for="tooltipVerySatisfied"
                                                src={imgLoveFace}
                                                alt="Icono Love Face"
                                                width="50rem"
                                                className="Equis"
                                            ></img>
                                        </div>
                                        <ReactTooltip id="tooltipVeryUnsatisfied" place="bottom" effect="solid">
                                            Muy Insatisfecho
                                        </ReactTooltip>
                                        <ReactTooltip id="tooltipUnsatisfied" place="bottom" effect="solid">
                                            Insatisfecho
                                        </ReactTooltip>
                                        <ReactTooltip id="tooltipNeutral" place="bottom" effect="solid">
                                            Neutral
                                        </ReactTooltip>
                                        <ReactTooltip id="tooltipSatisfied" place="bottom" effect="solid">
                                            Satisfecho
                                        </ReactTooltip>
                                        <ReactTooltip id="tooltipVerySatisfied" place="bottom" effect="solid">
                                            Muy Satisfecho
                                        </ReactTooltip>
                                    </div>

                                    <div className="Div-Left">
                                        <br></br>
                                        <label className="Message-Feedback">Mensaje</label>
                                    </div>
                                    <textarea
                                        className="Input-Message"
                                        id="inputMessage"
                                        rows="5"
                                        onChange={this.handleChangeMessage}
                                        value={this.state.text}
                                    ></textarea>
                                    <br></br>
                                    <div className="Div-Center">
                                        <Button
                                            variant="success"
                                            className="Submit-Button-Form"
                                            onClick={this.sendFeedback}
                                            id="sendButton"
                                        >
                                            Enviar
                                        </Button>
                                        <label className="Message" id="message"></label>
                                    </div>
                                </Col>
                                <Col lg={2}></Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={2}></Col>
                </Row>
                <div className="Notification" id="notificationDiv">
                    {this.state.notifications}
                </div>
                <div className="NoneNotification" id="NoneNotification" style={{ justifyContent: "center" }}>
                    <img src={noNotification} alt="Icono Dashboard" width="120rem" className="Icon-noNotification"></img>
                    <p className="text-noNotification">¡Felicidades! Estás al día con tus notificaciones</p>
                </div>
                <div className="profile-card" id="perfil">
                    <div className="profile-card__header">
                        <div className="profile-card__initial">{user.slice(0, 1)}</div>
                        <div>
                            <p className="profile-card__user"> {user}</p>
                            <p className="profile-card__email">{localStorage["Email"]}</p>
                            <p className="profile-card__brand">SWITCH DOT</p>
                        </div>
                    </div>
                    <hr />
                    <button className="profile-card__text" onClick={this.openFeedback}>
                        <p>Comentarios</p>
                        <i className="fa-solid fa-comments" />
                    </button>
                    <hr />
                    <button className="profile-card__text" onClick={this.doLogout}>
                        <p>Cerrar sesión</p>
                        <i className="fa-solid fa-arrow-right-from-bracket" />
                    </button>
                </div>
            </div>
        );
    }
}

export default NavMenuBar;
