import React from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FULL_DATE_PICKER_PROPS, TIME_PICKER_PROPS } from ".";

export const DateInput = ({
    handleChange,
    includeHours = false,
    label,
    maxDate,
    minDate,
    value,
    fullDatePicker = false,
    disabled,
    wrapperClassName,
    placeholder,
    filterDate,
}) => (
    <Form.Group className={`${label ? "input-group" : ""} ${wrapperClassName}`}>
        {label && <Form.Label className="input-label">{label}</Form.Label>}
        <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={value}
            onChange={handleChange}
            maxDate={maxDate}
            minDate={minDate}
            locale={"es-CO"}
            wrapperClassName="date-picker"
            disabled={disabled}
            placeholderText={placeholder}
            filterDate={filterDate}
            {...(fullDatePicker && FULL_DATE_PICKER_PROPS)}
            {...(includeHours && TIME_PICKER_PROPS)}
        />
    </Form.Group>
);
