import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import Footer from "components/Footer";
import NavMenuBar from "components/NavMenuBar";
import { Select } from "components/Select";
import { BarChart } from "components/BarChart";
import { LineChart } from "components/LineChart";
import { DateInput } from "components/DateInput";
import { PieChart } from "components/PieChart";
import { AnnualHistory, MonthlyHistory } from "./components";
import { useCameras } from "hooks/useCameras";
import { useDates } from "hooks/useDates";
import { useTraffic } from "hooks/useTraffic";
import { ALL_ACCESS } from "pages/Schedule";
import { closeSideBar } from "utils/Navbar";
import { getNewOptions } from "utils/Select";
import { addDays } from "utils/Date";
import { addThousands } from "utils/Value";
import { sumArray } from "utils/Array";
import { getCapacityPeaks, getMostVisitedCameras } from "utils/Traffic";
import { PERIOD_OPTIONS, getVisitsPerHour, getTextsFromPeaks, DEFAULT_DATE } from ".";
import "./Historical.scss";

const Historical = () => {
    const [filters, setFilters] = useState({
        cameras: [],
        period: PERIOD_OPTIONS[0],
        date: DEFAULT_DATE,
    });

    const { cameras, date, period } = filters;
    const filterByDays = period?.type === "days";
    const { cameraOptions } = useCameras();
    const { formattedDate, getNewDates } = useDates(filters.date);
    const { capacityPeaks, incomeData, showLoader, traffic } = useTraffic({ filters, formattedDate, filterByDays });
    const unit = filterByDays ? "Día" : "Hora";

    useEffect(() => {
        setFilters({ ...filters, cameras: cameraOptions.slice(1, cameraOptions.length) });
    }, [cameraOptions]);

    const handleChangingCameras = selectedOptions => {
        setFilters({
            ...filters,
            cameras: getNewOptions({ allOptions: cameraOptions, selectedOptions, allKey: ALL_ACCESS }),
        });
    };

    const handlePeriodChange = (option, name) => {
        if (option.type === "hours") {
            const [startDate, endDate] = [addDays(new Date(), -1), addDays(new Date(), -1)];
            startDate.setHours(8, 0);
            endDate.setHours(22, 0);
            return setFilters({
                ...filters,
                [name]: option,
                date: { start: startDate, end: endDate, day: startDate },
            });
        }

        return setFilters({ ...filters, [name]: option, date: DEFAULT_DATE });
    };

    const mostVisitedCameras = useMemo(() => getMostVisitedCameras(traffic, cameras), [traffic, cameras]);

    const totalVisits = useMemo(() => sumArray(incomeData), [incomeData]);

    const visitsPerHour = useMemo(() => (filterByDays ? [] : getVisitsPerHour(incomeData)), [incomeData, filterByDays]);

    const hourPeaks = useMemo(() => getCapacityPeaks(visitsPerHour), [visitsPerHour]);

    const { maximum, minimum } = getTextsFromPeaks(filterByDays);

    const peaks = filterByDays ? capacityPeaks : hourPeaks;

    return (
        <div className="historical">
            <NavMenuBar />
            <main className="historical__content mb-5" id="genericBody" style={{ width: "86%" }} onClick={closeSideBar}>
                <h1 className="historical__title">Tráfico histórico</h1>
                <div className="historical__filters">
                    <Select
                        className="historical__select--long"
                        options={cameraOptions}
                        isMulti
                        handleChange={handleChangingCameras}
                        placeholder="Accesos"
                        value={cameras}
                        wrapperClassName="historical__select--wrapper"
                    />
                    <Select
                        className="historical__select--short"
                        options={PERIOD_OPTIONS}
                        handleChange={option => handlePeriodChange(option, "period")}
                        placeholder="Periodo"
                        value={period}
                    />
                    {!filterByDays && (
                        <DateInput
                            handleChange={date => setFilters(getNewDates({ date, name: "day" }, filters))}
                            placeholder="Día"
                            value={date?.day}
                            maxDate={new Date()}
                        />
                    )}
                    <DateInput
                        handleChange={date => setFilters(getNewDates({ date, name: "start" }, filters))}
                        placeholder={`${unit} inicial`}
                        value={date?.start}
                        includeHours={!filterByDays}
                        maxDate={new Date()}
                    />
                    <DateInput
                        handleChange={date => setFilters(getNewDates({ date, name: "end" }, filters))}
                        placeholder={`${unit} final`}
                        value={date?.end}
                        includeHours={!filterByDays}
                        maxDate={new Date()}
                    />
                </div>
                {!incomeData.length || showLoader ? (
                    <Spinner animation="border" role="status" style={{ color: "#87ad36", margin: "auto", display: "block" }} />
                ) : (
                    <div className="historical__chart-container">
                        <div className="historical__total">
                            <p className="historical__total--value">{addThousands(totalVisits)}</p>
                            <p className="historical__total--text">Total de visitantes</p>
                        </div>
                        {filterByDays ? (
                            <BarChart
                                data={incomeData}
                                settings={{ barName: "Ingresos", dataKey: "value", xKey: "date", width: "100%" }}
                            />
                        ) : (
                            <LineChart
                                data={visitsPerHour}
                                settings={{ lineName: "Ingresos", lineKey: "value", xKey: "date", width: "100%" }}
                            />
                        )}
                    </div>
                )}
                {!showLoader && (
                    <div className="historical__resume">
                        <div className="historical__pie">
                            <h2 className="historical__pie-title">Distribución 4 accesos con más flujo</h2>
                            <PieChart
                                className="historical__pie-chart"
                                data={mostVisitedCameras}
                                settings={{
                                    width: 480,
                                    height: 220,
                                    legend: { layout: "vertical", verticalAlign: "top", align: "right" },
                                }}
                            />
                        </div>
                        <div className="historical__peaks">
                            <div>
                                <p className="historical__value historical__value--maximum">
                                    {addThousands(peaks.maximum?.value)}
                                </p>
                                <p className="historical__date">
                                    {maximum} {peaks.maximum?.date}
                                </p>
                            </div>
                            <div>
                                <p className="historical__value historical__value--minimum">
                                    {addThousands(peaks.minimum?.value)}
                                </p>
                                <p className="historical__date">
                                    {minimum} {peaks.minimum?.date}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <MonthlyHistory cameraOptions={cameraOptions} />
                <AnnualHistory cameraOptions={cameraOptions} />
            </main>
            <Footer />
        </div>
    );
};

export default Historical;
